/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Users give consent/decline cookies for Google Analytics
 * @module Epic.AppOrchard.Frame.GAPrivacyPolicy
 */

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, useDisclosure } from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { useSiteInfoState } from "ao/state/siteInfo";
import { cookieExists, createCookie, gACookieName, gaInitialize } from "ao/utils/helpers";
import React, { FC, memo, useCallback, useEffect } from "react";
import { AOLink } from "../Core";

/**
 * Users give consent/decline cookies for Google Analytics
 */
export const GAPrivacyPolicy: FC = memo(() => {
	//#region state/hooks
	const { isOpen, onOpen, onClose } = useDisclosure();
	const siteName = config.SiteName;
	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();
	const handleAccept = useCallback(() => {
		createCookie(gACookieName, "true", oneYearFromNow);
		gaInitialize(siteInfo.googleAnalyticsTrackingId);
		onClose();
	}, [onClose, oneYearFromNow, siteInfo.googleAnalyticsTrackingId]);

	const handleDecline = useCallback(() => {
		createCookie(gACookieName, "false", oneYearFromNow);
		onClose();
	}, [onClose, oneYearFromNow]);

	useEffect(() => {
		if (!cookieExists(gACookieName)) {
			onOpen();
		}
	}, [onOpen]);

	//#endregion

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			useInert={false}
			blockScrollOnMount={false}
			trapFocus={false}
			variant="clickThrough"
		>
			<ModalContent
				maxW="unset"
				w={{ base: "100%", sm: "75%", md: "50%" }}
				minW="320px"
				bottom="50px"
				pos={"absolute"}
				backgroundColor="#fff"
				border="solid 1px rgba(69, 141, 114, 1)"
				boxShadow="0 0 10px 0px rgba(0,0,0,.3)"
				margin="0"
			>
				<Box backgroundColor="rgba(69, 141, 114, .15)">
					<ModalBody pb="0">
						We use cookies to improve our website. By accepting, you will receive these cookies
						from {siteName}. Decline if you wish to use {siteName} without these cookies.{" "}
						<AOLink url="Resources/Privacy" color="#2c6da5">
							Read our privacy policy here
						</AOLink>
						.
					</ModalBody>

					<ModalFooter p="5px" justifyContent={"center"}>
						<Button
							colorScheme="black"
							backgroundColor={"white"}
							variant={"outline"}
							mr={3}
							onClick={handleAccept}
						>
							Accept
						</Button>
						<Button
							colorScheme="black"
							backgroundColor={"white"}
							variant={"outline"}
							onClick={handleDecline}
						>
							Decline
						</Button>
					</ModalFooter>
				</Box>
			</ModalContent>
		</Modal>
	);
});
