/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Favorites
 * @module Epic.AppOrchard.Frame.Favorites
 */

import { CloseIcon, StarIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import {
	AOLink as Link,
	DropdownHeader,
	DropdownList,
	DropdownMenu,
	DropdownMenuItem,
	DropdownTrigger,
	StatusModal,
} from "ao/components/Core";
import { addFavorite, getFavorites, removeFavorite } from "ao/data";
import { IFavorite } from "ao/types";
import { getCurrentLocation } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, useCallback, useEffect, useState } from "react";
import { MdAddCircle } from "react-icons/md";
import { HeaderMenuButton, HeaderMenuItem } from ".";
import { menuIsCurrentPage } from "./helpers";

interface IProps {
	isMobile: boolean;
}

/**
 * Bookmark favorite site pages
 *
 * NOTE: do not wrap this component in memo since it needs to rerender when the current page changes and that is not a prop
 */
export const Favorites: FC<IProps> = (props: IProps) => {
	const { isMobile } = props;
	//#region state/hooks
	const [favorites, setFavorites] = useState<IFavorite[]>([]);
	const [maxAllowed, setMaxAllowed] = useState(0);
	const [error, setError] = useState("");
	const [hasLoaded, setHasLoaded] = useState(false);
	const currentPath = getCurrentLocation(true).toLowerCase();
	const [currentPageFavId, setCurrentPageFavId] = useState<number | undefined>();

	const [isOpen, setIsOpen] = useState(false);

	const updateIsOpen = useCallback((open: boolean) => () => setIsOpen(open), []);

	useEffect(() => {
		const currentPageUrls = [currentPath, currentPath + "/index"];
		for (const fav of favorites) {
			if (menuIsCurrentPage(fav.Link, currentPageUrls)) {
				setCurrentPageFavId(fav.Id);
				return;
			}
		}
		setCurrentPageFavId(undefined);
	}, [currentPath, favorites]);

	const [loadingState, loadFavoritesFn] = useAsync(getFavorites, {
		executeImmediately: false,
		displayName: "getFavorites",
		onSuccess: (response) => {
			setFavorites(response.Data.Favorites);
			setMaxAllowed(response.Data.MaxAllowed);
			setHasLoaded(true);
		},
		onFailure: () => setError("Error loading favorites."),
	});
	const isLoading = isInFlight(loadingState);

	const [addingState, addFavoriteFn] = useAsync(addFavorite, {
		executeImmediately: false,
		displayName: "addFavorite",
		onSuccess: (response, extras) => {
			const [, curFavorites] = extras.params;
			setFavorites([...curFavorites, response.Data]);
		},
		onFailure: () => setError("Error adding favorite."),
	});

	const [removingState, removeFavoriteFn] = useAsync(removeFavorite, {
		executeImmediately: false,
		displayName: "removeFavorite",
		onSuccess: (response, extras) => {
			if (!response.Data.Success) {
				setError("Error removing favorite.");
				return;
			}
			const [removedId, curFavorites] = extras.params;
			setFavorites(curFavorites.filter((fav) => fav.Id !== removedId));
		},
		onFailure: () => setError("Error removing favorite."),
	});

	const handleAddOrRemove = useCallback(
		(isAdd: boolean, id?: number) => (ev: React.MouseEvent<HTMLButtonElement>) => {
			ev.stopPropagation();
			ev.preventDefault();
			if (isAdd) {
				addFavoriteFn(currentPath, favorites);
			} else {
				removeFavoriteFn(id!, favorites);
			}
			setError("");
			return false;
		},
		[addFavoriteFn, favorites, removeFavoriteFn, currentPath],
	);

	const isSaving = isInFlight(addingState) || isInFlight(removingState);

	const handleOpen = useCallback(() => {
		if (!hasLoaded) {
			loadFavoritesFn();
		}
		setIsOpen(true);
	}, [hasLoaded, loadFavoritesFn]);

	//#endregion

	return (
		<>
			<DropdownMenu
				onOpen={handleOpen}
				onClose={updateIsOpen(false)}
				placement="bottom"
				menuWidth={{ base: "100vw", sm: "50vw", lg: "25vw", xl: "18vw" }}
				variant="headerDropdown"
				showHeaderDivider={false}
			>
				<DropdownTrigger>
					<HeaderMenuButton
						padding={isMobile ? "8px" : "10px"}
						open={isOpen}
						aria-label="Open favorites"
						title="Open favorites"
						isIconButton
					>
						<StarIcon h={isMobile ? "4" : "6"} w={isMobile ? "4" : "6"} />
					</HeaderMenuButton>
				</DropdownTrigger>
				<DropdownHeader>
					{hasLoaded && favorites.length < maxAllowed && (
						<HeaderMenuItem
							onClick={handleAddOrRemove(true)}
							minW="unset"
							w="100%"
							url="javascript"
							aria-label="Add Current Page to Favorites"
							showIcon={false}
							disabled={currentPageFavId !== undefined || isSaving}
							title={currentPageFavId !== undefined ? "Current page is already a favorite" : ""}
						>
							<MdAddCircle
								size={25}
								style={{ display: "inline-block", marginRight: "5px" }}
								color="rgb(121,121,121)"
							/>
							<Box
								as="span"
								w="85%"
								overflow="hidden"
								textOverflow="ellipsis"
								verticalAlign="middle"
								display="inline-block"
							>
								Add Current Page to Favorites
							</Box>
						</HeaderMenuItem>
					)}
				</DropdownHeader>
				<DropdownList>
					{!hasLoaded || isLoading ? (
						<Box>Loading</Box>
					) : (
						<>
							{favorites.map((fav, index) => (
								<DropdownMenuItem useChildButton key={index}>
									<HeaderMenuItem
										key={index}
										minW="unset"
										lineHeight="unset"
										as={Link}
										url={fav.Link}
										menuId={fav.MenuId}
										title={
											fav.Name +
											(currentPageFavId === fav.Id ? " (this is the current page)" : "")
										}
										isForFavorites
									>
										<Box
											as="span"
											w="75%"
											overflow="hidden"
											textOverflow="ellipsis"
											verticalAlign="middle"
											display="inline-block"
										>
											{fav.Name}
										</Box>
										<IconButton
											aria-label={"Remove favorite"}
											w="10%"
											minW="unset"
											title={"Remove favorite"}
											variant="ghost"
											float="right"
											top="-6px"
											right="-6px"
											icon={<CloseIcon stroke="currentcolor" strokeWidth={2} />}
											_hover={{ color: "red" }}
											disabled={isSaving}
											onClick={handleAddOrRemove(false, fav.Id)}
										></IconButton>
									</HeaderMenuItem>
								</DropdownMenuItem>
							))}
						</>
					)}
				</DropdownList>
			</DropdownMenu>
			<StatusModal
				title="Error"
				message={`${error} Please try again later or contact your Epic representative if this continues to happen.`}
				isOpen={!!error}
				onClose={() => setError("")}
				status="error"
			></StatusModal>
		</>
	);
};
