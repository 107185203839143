/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Notifications
 * @module Epic.AppOrchard.Frame.Notifications
 */

import { CloseIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Divider,
	Flex,
	GridItem,
	Heading,
	Icon,
	IconButton,
	keyframes,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	SimpleGrid,
	Spacer,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { AOLink as Link, LinkButton, StatusModal } from "ao/components/Core";
import { dismissNotifications, newNotificationsCount, notificationList } from "ao/data";
import { INotification, IUserSecurity } from "ao/types";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { BsFillBellFill } from "react-icons/bs";
import { FaWrench } from "react-icons/fa";
import { HeaderMenuButton } from ".";

interface IProps {
	isMobile: boolean;
	userSecurity: IUserSecurity;
}

export const Notifications: FC<IProps> = memo((props: IProps) => {
	const { isMobile, userSecurity } = props;
	//#region state/hooks
	const spin = keyframes` from {transform: rotate(0deg);} to {transform: rotate(360deg)}`;
	const [unreadCount, setUnreadCount] = useState(0);
	const [error, setError] = useState("");
	const [notifications, setNotifications] = useState<INotification[]>([]);
	// hold refs of notifications so we can focus next one after dismissing
	const focusNextRef = useRef<HTMLButtonElement>();
	const [indexToFocus, setIndexToFocus] = useState<number | undefined>();
	const helptext =
		"View and edit notifications" + (unreadCount > 0 ? `. ${unreadCount} new notifications.` : "");

	/**
	 * Load unseen notification count
	 */
	const [, loadNewCount] = useAsync(newNotificationsCount, {
		executeImmediately: false,
		displayName: "newNotificationsCount",
		onSuccess: (response) => {
			setUnreadCount(response.Data.Count);
		},
	});

	/**
	 * Load unseen notification count every 60 seconds
	 */
	useEffect(() => {
		loadNewCount();
		const intervalId = setInterval(() => loadNewCount(), 60000);
		return () => clearInterval(intervalId);
	}, [loadNewCount]);

	/**
	 * Load list of active notifications
	 */
	const [loadingState, loadNotifications] = useAsync(notificationList, {
		executeImmediately: false,
		displayName: "notificationList",
		onSuccess: (response) => {
			const loadedNotifications = response.Data.map((n) =>
				n.NotificationDatetime
					? { ...n, NotificationDatetime: new Date(n.NotificationDatetime).toLocaleString() }
					: n,
			);
			setNotifications(loadedNotifications);
			setUnreadCount(0);
		},
		onFailure: () => setError("Error loading notifications."),
	});
	const isLoading = isInFlight(loadingState);

	/**
	 * Dismiss notifications
	 */
	const [savingState, dismissNotificationsFn] = useAsync(dismissNotifications, {
		executeImmediately: false,
		displayName: "dismissNotifications",
		onSuccess: (response, extras) => {
			if (!response.Data.Success) {
				setError("Error dismissing notifications.");
				return;
			}
			const [dismissedIds, allNotifications] = extras.params;

			const remainingNotifications = allNotifications.filter(
				(n) => !dismissedIds.includes(n.NotificationId),
			);

			setNotifications(remainingNotifications);

			//focus next notification (if any are left) after dismissing single notification for easy of keyboard use
			if (remainingNotifications.length > 0 && dismissedIds.length === 1) {
				let prevIndex = allNotifications.findIndex((n) => n.NotificationId === dismissedIds[0]);
				if (prevIndex === remainingNotifications.length)
					prevIndex = remainingNotifications.length - 1;
				setIndexToFocus(prevIndex);
				focusNextRef?.current?.focus();
			}
		},
		onFailure: () => setError("Error dismissing notifications."),
	});
	const isSaving = isInFlight(savingState);

	const handleDismiss = useCallback(
		(dismissAll: boolean, notificationId?: number) => (ev: React.MouseEvent<HTMLButtonElement>) => {
			const toDismiss = dismissAll ? notifications.map((n) => n.NotificationId) : [notificationId!];
			dismissNotificationsFn(toDismiss, notifications);
			ev.preventDefault();
			ev.stopPropagation();
			return false;
		},
		[dismissNotificationsFn, notifications],
	);

	//#endregion

	return (
		<>
			<Popover onOpen={loadNotifications} gutter={2}>
				{({ isOpen, onClose }) => (
					<>
						<PopoverTrigger>
							<HeaderMenuButton
								padding={isMobile ? "8px" : "10px"}
								aria-label={helptext}
								// close dropdown when SHIFT+CLICK on trigger element to workaround Chakra-UI issue https://github.com/chakra-ui/chakra-ui/issues/6920
								onKeyUp={(e: React.KeyboardEvent<HTMLButtonElement>) => {
									if (e.key === "Tab" && e.shiftKey) {
										onClose();
										e.preventDefault();
										e.stopPropagation();
										return false;
									}
								}}
								title={helptext}
								open={isOpen}
								isIconButton
							>
								<>
									<BsFillBellFill
										size={isMobile ? 15 : 20}
										style={{ display: "inline-block" }}
									/>
									{/* Show red circle for unread notifications */}
									{unreadCount > 0 && (
										<Box
											as="span"
											display="inline-block"
											textAlign="center"
											whiteSpace="nowrap"
											verticalAlign="middle"
											borderRadius="10px"
											position="absolute"
											left={{ base: "17px", lg: "20px" }}
											top={{ base: "13px", lg: "20px" }}
											p="1px 4px"
											backgroundColor="rgb(231, 47, 108)"
											fontSize="8px"
											w={{ base: "8px", lg: "10px" }}
											h={{ base: "8px", lg: "10px" }}
										></Box>
									)}
								</>
							</HeaderMenuButton>
						</PopoverTrigger>
						<PopoverContent
							w={{ base: "100vw", sm: "50vw", lg: "20vw" }}
							boxShadow="0 6px 12px rgb(0 0 0 / 18%)!important"
						>
							<PopoverBody p="0">
								{userSecurity.hasWhatsNew && !userSecurity.showWhatsNew && (
									<LinkButton
										variant="outline"
										borderRadius="md md 0 0"
										w="100%"
										url="ProgramDetails/ExtendedContent?useType=WhatIsNew"
										target="_blank"
										justifyContent="flex-start"
										border="none"
									>
										<Icon
											viewBox="0 0 160 160"
											fill="rgb(121,121,121)"
											animation={`${spin} infinite 1.25s linear`}
											h="23px"
											w="23px"
											mr="5px"
											verticalAlign="middle"
											stroke="rgb(121,121,121)"
										>
											<g>
												<path d="M70 9.5h20v47H70zm0 92.5h20v48.3H70zm-6.3-41.2L54.4 77 15 54.2 24.3 38zm81.3 45l-9.3 16.2-40.2-23.3 9.2-16.2zM54.6 83.1l9.5 16.2L23.9 122l-9.5-16.2zM135.7 38l9.3 16.2-39.6 22.9-9.2-16.2z" />
											</g>
										</Icon>
										What's New
									</LinkButton>
								)}
								<Divider />
								<Flex p="3px 20px" m="5px 0">
									<Heading as="h3" color="#777" position="relative" top="3px">
										Notifications
									</Heading>
									<Spacer />
									<IconButton
										aria-label="Manage notification preferences"
										as={Link}
										url="Notifications"
										title="Manage notification preferences"
										size="lg"
										w="2em"
										h="30px"
										minW="unset"
										variant="outline"
										icon={<FaWrench />}
										disabled={isSaving}
									></IconButton>
								</Flex>
								<Divider />
								<Box minH="14em" maxH="14em" overflowY="auto" overflowX="clip">
									{isLoading ? (
										<Box p="0.25em">Loading...</Box>
									) : (
										notifications.map((notification, index) => (
											<Box
												key={index}
												background={index % 2 === 0 ? "rgba(180,180,180,.1)" : ""}
												_hover={{ background: "gray.200" }}
												p="0.25em"
											>
												<SimpleGrid columns={12}>
													<GridItem colSpan={10}>
														<LinkButton
															url={notification.LinkAddress}
															ref={
																index === indexToFocus
																	? focusNextRef
																	: undefined
															}
															title="View more information"
															w="100%"
															display="inline-table"
															variant="ghost"
															verticalAlign="middle"
															fontWeight={notification.Unseen ? 600 : "normal"}
															_hover={{
																textDecoration: "none",
																background: "unset",
																color: "black",
															}}
														>
															<Box whiteSpace="break-spaces">
																{notification.DisplayText}
															</Box>
															<Box
																fontSize="0.8em"
																color="rgb(100, 100, 100)"
																mt="3px"
															>
																{notification.NotificationDatetime}
															</Box>
														</LinkButton>
													</GridItem>
													<GridItem colSpan={2}>
														<IconButton
															aria-label="Remove notification"
															minW="unset"
															title="Remove notification"
															variant="ghost"
															float="right"
															mr="1.5em"
															icon={
																<CloseIcon
																	stroke="currentcolor"
																	strokeWidth={2}
																/>
															}
															_hover={{ color: "red" }}
															disabled={isSaving}
															onClick={handleDismiss(
																false,
																notification.NotificationId,
															)}
														></IconButton>
													</GridItem>
												</SimpleGrid>
											</Box>
										))
									)}
								</Box>
								<Divider />
								<Box mt="9px" p="3px 20px" mb="5px">
									<Button
										h="30px"
										minH="unset"
										w="100%"
										onClick={handleDismiss(true)}
										disabled={isSaving || notifications.length === 0}
										variant="outline"
										title="Remove all notifications"
									>
										Dismiss all
									</Button>
								</Box>
							</PopoverBody>
						</PopoverContent>
					</>
				)}
			</Popover>
			<StatusModal
				title="Error"
				message={`${error} Please try again later or contact your Epic representative if this continues to happen.`}
				isOpen={!!error}
				onClose={() => setError("")}
				status="error"
			></StatusModal>
		</>
	);
});
