/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Button to mark app as internal.
 * @module Epic.AppOrchard.Core.MarkInternalButton
 */

import { WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { As, Button, useToast } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { markAsInternal } from "ao/data";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { AOLink as Link, ConfirmationModal, StatusModal } from "..";
import { getBaseUrl } from "../../../utils/helpers";

interface IProps {
	appId: number;
	/** button-like component to render the button as */
	buttonAs?: As;
	successHandler: () => void;
	caption?: string;
	hasApiContextWarning?: boolean;
	hasApiContextWarningOverride?: boolean;
	/** Alternate modal body for build Apps*/
	buildAppsModalBody?: JSX.Element;
	isDisabled?: boolean;
	title?: string;
}

export const MarkInternalButton: FC<IProps> = memo((props: IProps) => {
	const {
		appId,
		buttonAs,
		successHandler,
		caption,
		hasApiContextWarning,
		hasApiContextWarningOverride,
		buildAppsModalBody,
		isDisabled,
		title,
	} = props;

	//#region state/handlers
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

	const handleCloseWarningModal = useCallback(() => setShowWarningModal(false), []);
	const handleCloseErrorModal = useCallback(() => setShowErrorModal(false), []);

	const toast = useToast();
	const handleSuccess = useCallback(() => {
		successHandler();
		toast({
			title: "Success",
			description: "App successfully marked as internal",
			status: "success",
			duration: config.ToastDuration,
			isClosable: true,
		});
	}, [successHandler, toast]);

	const [savingState, markInternalFn] = useAsync(markAsInternal, {
		executeImmediately: false,
		displayName: "markInternal",
		onSuccess: handleSuccess,
		onFailure: () => setShowErrorModal(true),
	});

	const handleConfirmMarkInternal = useCallback(() => {
		markInternalFn(appId);
		setShowConfirmation(false);
	}, [appId, markInternalFn]);

	const handleButtonClick = useCallback(() => {
		if (hasApiContextWarning) {
			setShowWarningModal(true);
		} else {
			setShowConfirmation(true);
		}
	}, [hasApiContextWarning]);

	const handleToggleConfirm = useCallback((show: boolean) => () => setShowConfirmation(show), []);

	const handleAcceptWarningModal = useCallback(() => {
		setShowConfirmation(true);
		setShowWarningModal(false);
	}, []);

	const isSaving = isInFlight(savingState);
	//#endregion state/handlers

	const MarkInternalButton = buttonAs || Button;
	const isCentered = buildAppsModalBody ? true : false;

	return (
		<>
			<MarkInternalButton
				colorScheme={buildAppsModalBody ? "blue" : "green"}
				title={
					title ??
					"Mark app as internal, locking it down for editing for most users. This is required before the app can be activated for Production."
				}
				aria-label="Mark app as internal, locking it down for editing for most users. This is required before the app can be activated for Production."
				leftIcon={<BiLockAlt size={20} />}
				loadingText="Saving"
				isLoading={isSaving}
				isDisabled={isDisabled}
				onClick={handleButtonClick}
			>
				{caption || "Mark Internal"}
			</MarkInternalButton>
			<ConfirmationModal
				title="Mark App Internal"
				message={
					buildAppsModalBody ??
					"Are you sure you want to mark this app as internal? This will lock it down for editing for most users from your organization."
				}
				acceptCaption="Continue"
				cancelCaption="Cancel"
				isOpen={showConfirmation}
				onCancel={handleToggleConfirm(false)}
				onAccept={handleConfirmMarkInternal}
				isCentered={isCentered}
			/>
			<ConfirmationModal
				title={
					<>
						{hasApiContextWarningOverride ? (
							<WarningTwoIcon
								fontSize="20px"
								color="yellow.500"
								marginRight="5px"
								marginBottom="3px"
							/>
						) : (
							<WarningIcon fontSize="20px" color="red" marginRight="5px" marginBottom="3px" />
						)}

						{(hasApiContextWarningOverride ? "Warning" : "Error") + ": Unsupported APIs Used"}
					</>
				}
				message={
					<>
						You {hasApiContextWarningOverride ? "should " : "can"}not activate this app for your
						environments or mark the app internal use until this is corrected. &nbsp;
						<Link url={getBaseUrl() + "Article?docId=usercontext"} target="_blank">
							Click to see documentation on application user context and APIs
						</Link>
					</>
				}
				acceptCaption={hasApiContextWarningOverride ? "Continue Anyway" : "Ok"}
				cancelCaption="Cancel"
				showCancel={hasApiContextWarningOverride}
				isOpen={showWarningModal}
				onCancel={handleCloseWarningModal}
				onAccept={hasApiContextWarningOverride ? handleAcceptWarningModal : handleCloseWarningModal}
				isCentered={isCentered}
			/>
			<StatusModal
				title="Error"
				message="Error marking this app as internal. Please try again later or contact your Epic representative if this continues to happen."
				isOpen={showErrorModal}
				onClose={handleCloseErrorModal}
				status="error"
			></StatusModal>
		</>
	);
});
