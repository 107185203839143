/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Type declarations for connector management stuff
 * @module Epic.AppOrchard.Types.ConnectorManagement
 */

import { IKeyedListObject } from ".";

export interface IConnectorManagementAlias extends IKeyedListObject {
	id: number;
	recordId?: number;
	sortOrder: number;
	alias: string;
	comment?: string;
	lastUpdatedBy?: number;
	LastUpdatedOn?: string;
}

interface IConnectorAppCategory extends IKeyedListObject {
	appId: number;
	categoryId: number;
	categoryName: string;
}

export interface ISqlRecord extends IKeyedListObject {
	id: number;
	name: string;
}

export interface ISqlTrackRecord extends ISqlRecord {
	trackId: string;
}

export interface IConnectorManagementLookups {
	apps: ISqlRecord[];
	reviewStatuses: ISqlRecord[];
	reviewers: ISqlRecord[];
	appCategories: ISqlRecord[];
}

export interface IConnectorVendor extends ISqlTrackRecord {
	website?: string;
}

export interface IConnectorAppForVendor extends ISqlTrackRecord {
	isEngine: boolean;
	categories: ISqlRecord[];
}

export enum ManagementTabs {
	Apps = 0,
}

export interface IConnectorApp extends IKeyedListObject {
	id: number;
	name: string;
	trackId: string;
	clientAppId: number;
	vendor: IConnectorVendor;
	comment: string;
	isPrivateApp: boolean;
	isDeleted: boolean;
	isEngine: boolean;
	isEpicOnFHIR: boolean;
	createdBy?: ISqlRecord;
	categories: ISqlRecord[];
	aliases: IConnectorManagementAlias[];
	reviewStatus?: ISqlRecord;
	reviewer?: ISqlRecord;
	reviewedOn: string;
	lastUpdatedBy?: ISqlRecord;
	lastUpdatedOn: string;
	addedOn: string;
}

export interface IConnectorAppListItem {
	id: number;
	trackId?: string;
	clientAppId: number;
	name: string;
	reviewStatus: ISqlRecord;
	reviewer?: ISqlRecord;
	reviewedOn?: string;
	createdBy?: ISqlRecord;
	addedOn?: string;
	comment?: string;
	isPrivateApp: boolean;
	isDeleted: boolean;
	isEngine: boolean;
	isEpicOnFHIR: boolean;
	appCategories?: IConnectorAppCategory[];
	appAliases?: IConnectorManagementAlias[];
	mergedAppTargetId?: number;
}
