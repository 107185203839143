/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Site Search
 * @module Epic.AppOrchard.Frame.SiteSearch
 */

import { Search2Icon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { ISuccessHandler, useAsync } from "@epic/react-async-hook";
import {
	AOLink as Link,
	DropdownFooter,
	DropdownHeader,
	DropdownList,
	DropdownMenu,
	DropdownMenuItem,
	DropdownTrigger,
	SearchInput,
} from "ao/components/Core";
import { siteSearch } from "ao/data";
import { ISiteSearchResult } from "ao/types";
import { gaEvent } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { HeaderMenuButton, HeaderMenuItem } from ".";

interface IProps {
	isMobile: boolean;
}

export const SiteSearch: FC<IProps> = memo((props: IProps) => {
	const { isMobile } = props;
	//#region state and hooks
	const [searchTerm, setSearchTerm] = useState("");
	const [hasError, setHasError] = useState(false);
	const [page, setPage] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [searchedForTerm, setSearchedForTerm] = useState(false);
	const [moreToLoad, setMoreToLoad] = useState(false);
	const [noResults, setNoResults] = useState(false);
	const [focusShowMore, setFocusShowMore] = useState(false);
	const [results, setResults] = useState<ISiteSearchResult[]>([]);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const [focusedElement, setFocusedElement] = useState<RefObject<FocusableElement>>(searchInputRef);
	const showMoreRef = useRef<HTMLButtonElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);

	const updateIsOpen = useCallback((open: boolean) => () => setIsOpen(open), []);

	useEffect(() => {
		const hasMoreToLoad = searchTerm.length > 0 && results.length > 0 && results.length < totalCount;
		setMoreToLoad(hasMoreToLoad);
		setNoResults(searchedForTerm && searchTerm.length > 0 && results.length === 0);
		setFocusedElement(focusShowMore && hasMoreToLoad ? showMoreRef : searchInputRef);
	}, [focusShowMore, moreToLoad, results.length, searchedForTerm, searchTerm.length, totalCount]);

	const handleSearchSuccess = useCallback<ISuccessHandler<typeof siteSearch>>((response, extras) => {
		const [searchText, pageNum, currentResults] = extras.params;

		setTotalCount(response.Data.TotalCount);
		const updatedResults =
			pageNum > 0 ? [...currentResults, ...response.Data.Results] : response.Data.Results;
		setResults(updatedResults);
		setPage(pageNum);
		if (pageNum > 0) {
			// scroll to bottom of scroll region
			scrollRef?.current?.scroll({ top: scrollRef?.current.scrollHeight, behavior: "smooth" });
		}

		setHasError(false);
		setSearchedForTerm(true);

		gaEvent({
			category: "Search",
			action: "Click",
			label: searchText,
		});
	}, []);

	const [searchingState, siteSearchFn] = useAsync(siteSearch, {
		executeImmediately: false,
		displayName: "siteSearch",
		// `extras` will contain `params` field which has the params passed to siteSearch
		// this is needed rather than using some values directly from state in the success handler because
		// that will result in a stale closure (https://gitlab.epic.com/foundations/react-async-hook/-/issues/2)
		onSuccess: (response, extras) => handleSearchSuccess(response, extras),
		onFailure: () => setHasError(true),
	});
	const isSearching = isInFlight(searchingState);

	const onSearchClicked = useCallback(() => {
		siteSearchFn(searchTerm, 0, results);
	}, [siteSearchFn, searchTerm, results]);

	const onShowMoreClicked = useCallback(() => {
		setFocusShowMore(true);
		siteSearchFn(searchTerm, page + 1, results);
	}, [siteSearchFn, searchTerm, page, results]);

	const handleChangeSearchTerm = useCallback((newSearchTerm: string) => {
		setResults([]);
		setPage(0);
		setMoreToLoad(false);
		setHasError(false);
		setSearchedForTerm(false);
		setSearchTerm(newSearchTerm);
		setFocusShowMore(false);
	}, []);

	const handleKeyPress = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>): void => {
			if (e.key === "Enter" && !searchedForTerm) {
				onSearchClicked();
			}
		},
		[onSearchClicked, searchedForTerm],
	);

	//#endregion

	return (
		<DropdownMenu
			initialFocusRef={focusedElement}
			menuWidth={{ base: "100vw", sm: "60vw", lg: "20vw" }}
			placement="bottom"
			variant="headerDropdown"
			onOpen={updateIsOpen(true)}
			onClose={updateIsOpen(false)}
		>
			<DropdownTrigger>
				<HeaderMenuButton
					padding={isMobile ? "8px" : "10px"}
					aria-label="Search the site for Menus, APIs, and Documents"
					title="Search the site for Menus, APIs, and Documents"
					open={isOpen}
					isIconButton
				>
					<Search2Icon h={isMobile ? "4" : "6"} w={isMobile ? "4" : "6"} />
				</HeaderMenuButton>
			</DropdownTrigger>
			<DropdownHeader>
				<SearchInput
					placeholder="Search for menus, APIs, documents"
					onChangeCallback={handleChangeSearchTerm}
					inputProps={{
						ref: searchInputRef,
						onKeyUp: handleKeyPress,
					}}
					inputGroupProps={{ mt: "8px", w: "96%", ml: "2%" }}
					showSearchButton
					fullyDisabled={isSearching}
					searchDisabled={searchedForTerm}
					onSearchClicked={onSearchClicked}
				/>
			</DropdownHeader>
			<DropdownList>
				{results.length > 0 && (
					<Box minH="14em" maxH="14em" overflowY="auto" overflowX="clip" ref={scrollRef}>
						{results.map((r, index) => (
							<DropdownMenuItem useChildButton key={index}>
								<HeaderMenuItem
									key={index}
									as={Link}
									url={r.Link}
									showIcon={false}
									whiteSpace="normal"
									borderWidth="0 0 1px"
									h="unset"
									p="10px"
									m="2px"
									w="unset"
								>
									{r.Name}
								</HeaderMenuItem>
							</DropdownMenuItem>
						))}
					</Box>
				)}
			</DropdownList>
			<DropdownFooter>
				{moreToLoad && (
					<Button
						variant="link"
						colorScheme="blue"
						onClick={onShowMoreClicked}
						disabled={isSearching}
						w="100%"
						p="2px"
						borderRadius="0"
						ref={showMoreRef}
					>
						Show more results
					</Button>
				)}
				{(noResults || hasError) && (
					<Text w="100%" p="2px" textAlign="center">
						{noResults ? "Found 0 results" : "Error loading results"}
					</Text>
				)}
			</DropdownFooter>
		</DropdownMenu>
	);
});
