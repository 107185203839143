/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Link to preview downloadable build and then actually download it
 * @module Epic.AppOrchard.Core.DownloadBuildLink
 */

import {
	Box,
	Button,
	Image,
	ImageProps,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { previewDownloadableBuild } from "ao/data";
import { IPreviewDownloadBuildResponseModel, ITCDocumentInfo } from "ao/types";
import { getBaseUrl } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { StatusModal } from "..";

interface IProps {
	appId: number;
	/** whether to show the downloadable build icon */
	showIcon?: boolean;
	baseUrlOverride?: string;
}

/** Link to preview downloadable build and then actually download it  */
export const DownloadBuildLink: FC<IProps> = memo((props: IProps) => {
	const { appId, showIcon, baseUrlOverride } = props;

	//#region state/handlers
	const baseUrl = useMemo(() => baseUrlOverride ?? getBaseUrl(), []);

	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
	const [tcPackages, setTcPackages] = useState<ITCDocumentInfo[]>([]);
	const [showOlderVersionPackages, setShowOlderVersionPackages] = useState<boolean>(false);
	const numPackagesToShowInitially = 5;

	const handlePreviewModalOpen = useCallback((show: boolean) => () => setIsPreviewModalOpen(show), []);
	const handleToggleErrorModal = useCallback((show: boolean) => () => setShowErrorModal(show), []);
	const handleToggleShowOtherTCPackages = useCallback(
		() => setShowOlderVersionPackages(!showOlderVersionPackages),
		[showOlderVersionPackages],
	);

	const handlePreviewDownloadableBuildSuccess = useCallback(
		(response: IPreviewDownloadBuildResponseModel) => {
			setIsPreviewModalOpen(true);
			const sortedPackages = response.packageInfo.sort((a, b) => b.epicCode - a.epicCode);
			setTcPackages(sortedPackages);
		},
		[],
	);

	const [loadingPreviewState, previewDownloadableBuildFn] = useAsync(previewDownloadableBuild, {
		executeImmediately: false,
		displayName: "previewDownloadableBuild",
		onSuccess: (response) => handlePreviewDownloadableBuildSuccess(response),
		onFailure: handleToggleErrorModal(true),
	});

	const isLoadingPreview = isInFlight(loadingPreviewState);

	const handlePreviewClicked = useCallback(() => {
		previewDownloadableBuildFn(appId);
	}, [appId, previewDownloadableBuildFn]);

	//#endregion

	return (
		<>
			<Button
				variant="link"
				colorScheme="blue"
				onClick={handlePreviewClicked}
				disabled={isLoadingPreview}
				title="View downloadable build for this app"
			>
				{showIcon && <DownloadBuildIcon mr="2px" />}
				{isLoadingPreview ? "Loading....   " : "Download Build"}
			</Button>
			<Modal isOpen={isPreviewModalOpen} onClose={handlePreviewModalOpen(false)} size="5xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Download Turbocharger Packages For Application Build</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th
										w="18%"
										title="The minimum Epic version your build environment must be on to load the records in this Turbocharger package."
									>
										Min Epic Version
									</Th>
									<Th
										w="18%"
										title="The maximum Epic version your build environment can be on to load the records in this Turbocharger package."
									>
										Max Epic Version
									</Th>

									<Th w="23%">Master Files</Th>
									<Th w="40%">Package Link</Th>
								</Tr>
							</Thead>
							<Tbody>
								{tcPackages
									.slice(
										0,
										showOlderVersionPackages ? undefined : numPackagesToShowInitially,
									)
									.map((pack) => (
										<Tr key={pack.documentId}>
											<Td>{pack.minEpicVersion}</Td>
											<Td>{pack.epicVersion}</Td>
											<Td wordBreak="break-all">{pack.masterFiles}</Td>
											<Td wordBreak="break-all">
												<Link
													target="_blank"
													href={`${baseUrl}Gallery/DownloadBuild?clientApplicationId=${appId}&documentId=${pack.documentId}&fileId=${pack.extendedContentId}`}
												>
													{pack.documentName}
												</Link>
											</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
						{tcPackages.length > numPackagesToShowInitially && (
							<>
								<Button
									variant="link"
									colorScheme="blue"
									onClick={handleToggleShowOtherTCPackages}
									mt="0.5em"
									display="block"
								>
									{showOlderVersionPackages
										? "Hide packages from older versions"
										: "Show packages from older versions"}
								</Button>
							</>
						)}
					</ModalBody>

					<ModalFooter>
						<Box mr="1em">
							This build can only be loaded into your Epic system by an approved system
							administrator. See the{" "}
							<Link
								href="https://galaxy.epic.com/Redirect.aspx?DocumentID=100001391&PrefDocID=102000"
								target="_blank"
							>
								Review Turbocharger Package Contents
							</Link>{" "}
							and{" "}
							<Link
								href="https://galaxy.epic.com/Redirect.aspx?DocumentID=100001392&PrefDocID=102000"
								target="_blank"
							>
								Import a Turbocharger Package
							</Link>{" "}
							sections of the Turbocharger Setup and Support Guide for more information about
							loading Turbocharger packages into your system.
						</Box>
						<Button onClick={handlePreviewModalOpen(false)}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<StatusModal
				title="Error Loading Downloadable Build"
				message="Error loading downloadable build. Please try again later or contact your Epic representative if this continues to happen."
				isOpen={showErrorModal}
				onClose={handleToggleErrorModal(false)}
				status="error"
			></StatusModal>
		</>
	);
});

export const DownloadBuildIcon: FC<ImageProps> = memo((props: ImageProps) => {
	const { ...rest } = props;
	const baseUrl = useMemo(() => getBaseUrl(), []);
	const turbochargerLogo = `${baseUrl}Content/images/Turbocharger_Rocket.png`;
	return (
		<Image
			src={turbochargerLogo}
			title="This app has downloadable build"
			aria-label="This app has downloadable build"
			{...rest}
		/>
	);
});
