/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Generic helper functions not related to a particular UI framework
 * @module Epic.AppOrchard.Utils.Helpers
 */

import { config } from "ao/appConfig";
import ReactGA from "react-ga";
import { InstallStatus, IOrganizationWithAssignment, IUserAvailableOrganizations } from "../data";
import {
	IUserSecurity,
	OrganizationStatus,
	OrganizationUserAssociation,
	ParentMenu,
	ProgramLevel,
	TermsType,
	UserAccountType,
	YesNoFilterOptions,
} from "../types";

export const OneYearInMilliseconds = 31536000000;
const ReplaceWithSpaceCharsRegex = /-|\.|,|\/|\\/;
const IgnoreCharsRegex = /'|"/;

function replacePunctuation(str: string) {
	if (!str) return "";
	return str.replace(ReplaceWithSpaceCharsRegex, " ").replace(IgnoreCharsRegex, "");
}

/**
 * Checks if stringToSearch contains search term(s).
 * If searchTerm has multiple pieces (delimited by space) AND logic will be used to between search terms to narrow results
 *
 * @param searchTerm term(s) to search for
 * @param stringToSearch string subject to search
 */
export function searchHasMatch(
	searchTerm: string,
	stringToSearch: string,
	replacePunct: boolean = true,
): boolean {
	if (!searchTerm || !stringToSearch) return false;

	if (replacePunct) {
		searchTerm = replacePunctuation(searchTerm);
		stringToSearch = replacePunctuation(stringToSearch);
	}

	const stringToSearchSplit = stringToSearch.toLowerCase().split(" ");

	if (searchTerm.includes(" ")) {
		const searchTermSplit = searchTerm.toLowerCase().split(" ");
		return searchTermSplit.every((term) => stringToSearchSplit.some((subStr) => subStr.startsWith(term)));
	} else {
		const searchTermLower = searchTerm.toLowerCase();
		return stringToSearchSplit.some((subStr) => subStr.startsWith(searchTermLower));
	}
}

/** Check if the given program level is for a customer */
export function isCustomerProgramLevel(programLevel: ProgramLevel): boolean {
	return programLevel === ProgramLevel.EpicCommunityMember;
}

/**
 * Find which org should be selected by default in org selection workflows
 * @param orgsForUser orgs that show in the dropdown
 * @returns org that should be selected
 */
export function getDefaultSelectedOrg(
	orgsForUser: IUserAvailableOrganizations,
): IOrganizationWithAssignment | undefined {
	if (orgsForUser.defaultOrganization) {
		return orgsForUser.defaultOrganization;
	}
	if (orgsForUser.organizations && orgsForUser.organizations.length > 0) {
		const firstAssigned = orgsForUser.organizations.find((org) => org.isUserAssigned);
		return firstAssigned ? firstAssigned : orgsForUser.organizations[0];
	}
	return undefined;
}

//#region URL, cookies, web stuff

/**
 * Get the base URL
 * @returns BaseURL of website
 */

export function getBaseUrl(): string {
	if (isLocalhost()) {
		return getApiBaseUrl();
	} else {
		return (
			window.location.protocol +
			"//" +
			window.location.hostname +
			(window.location.port && ":" + window.location.port) +
			"/"
		);
	}
}

/**
 * Get the base URL for API calls
 * @returns BaseURL of website
 */

export function getApiBaseUrl(): string {
	return (
		window.location.protocol +
		"//" +
		window.location.hostname +
		(window.location.port && ":" + window.location.port) +
		(window.location.port === "44305" ||
		window.location.hostname.toLowerCase().indexOf(config.SitePath.toLowerCase()) < 0
			? `/${config.SitePath}`
			: "") +
		"/"
	);
}

/**
 * @param path string to append to Base Url
 *  @param precomputedBaseUrl base URL if already computed
 * @returns BaseUrl prepended to path
 */
export function getFullUrl(path: string, precomputedBaseUrl?: string): string {
	const baseUrl = precomputedBaseUrl || getBaseUrl();
	const index = path.indexOf(baseUrl);
	if (index !== -1) {
		path = path.substring(index, path.length);
	}
	if (baseUrl.slice(baseUrl.length - 1) !== "/") {
		if (path.slice(0, 1) !== "/") {
			path = "/" + path;
		}
	} else {
		if (path.slice(0, 1) === "/") {
			path = path.substring(1);
		}
	}
	return baseUrl + path;
}

/**
 * @param path string to append to Base Url
 *  @param precomputedBaseUrl base URL if already computed
 * @returns BaseUrl prepended to path
 */
export function getApiFullUrl(path: string, precomputedBaseUrl?: string): string {
	const baseUrl = precomputedBaseUrl || getApiBaseUrl();
	const index = path.indexOf(baseUrl);
	if (index !== -1) {
		path = path.substring(index, path.length);
	}
	if (baseUrl.slice(baseUrl.length - 1) !== "/") {
		if (path.slice(0, 1) !== "/") {
			path = "/" + path;
		}
	} else {
		if (path.slice(0, 1) === "/") {
			path = path.substring(1);
		}
	}
	return baseUrl + path;
}

/**
 * Get path and optionally query string of the current page
 * @param includeQuery whether to include the query string
 * @returns path of current page without protocol, domain, and port
 */
export function getCurrentLocation(includeQuery: boolean = false) {
	const sitePath = config.SitePath.toLowerCase();
	const sitePathAlternate = config.SitePathAlternate.toLowerCase();

	const currentPath = window.location.pathname + (includeQuery ? window.location.search : "");
	const hostname = window.location.hostname.toLowerCase();
	const isDev =
		window.location.port === "44305" ||
		(hostname.indexOf(sitePath) < 0 && hostname.indexOf(sitePathAlternate) < 0);
	if (isDev) {
		return currentPath.slice(`/${sitePath}`.length);
	}
	return currentPath;
}

export function getReturnUrl(searchParams: URLSearchParams) {
	const returnUrlParam = getCaseInsensitiveParam(searchParams, "returnUrl");
	if (returnUrlParam !== null) {
		return encodeURIComponent(returnUrlParam);
	}
	const currentUrl = getCurrentLocation(true);
	if (currentUrl !== "/") {
		return currentUrl;
	}
	return "";
}

/**
 * removes http, https, or custom URI scheme from beginning of URI
 * @param uri string url input
 * @returns url without URI scheme
 */
export function stripURIScheme(uri?: string): string {
	if (uri === null || uri === undefined) {
		return "";
	}

	if (hasCustomUriScheme(uri)) {
		return uri.replace(customUriSchemePattern, "");
	}

	const pattern = /^https?:\/\//i;
	const url = uri.replace(pattern, "");
	return url;
}

/**
 *  Extract the URI scheme (http, https or custom) from a URI
 * @param uri string URI to process
 * @returns URI scheme
 */

export function extractURIScheme(uri: string): string {
	if (uri === null || uri === undefined) {
		return "";
	}

	if (hasCustomUriScheme(uri)) {
		return "custom";
	}

	const https = /^https:\/\//i;
	if (uri.match(https)) {
		return "https://";
	} else {
		return "http://";
	}
}

const customUriSchemePattern = /^custom/i;

export function hasCustomUriScheme(uri: string): boolean {
	return !!uri.match(customUriSchemePattern);
}

export const AOTimeZoneCookie = "AOTimezoneOffset";
export const AMVendorLoginCookie = "AppMarketVendorLogin";
export const AOVendorLoginCookie = "AOVendorLogin";

/**
 * Get value for a cookie
 * @param name cookie key/name
 * @returns value for a cookie if found else undefined
 */
export function getCookieValue(name: string): string | undefined {
	for (var cookiePair of document.cookie.split(";")) {
		var cookie = cookiePair.split("=");
		if (name === cookie[0].trim()) {
			return cookie[1].trim();
		}
	}
	return undefined;
}

/**
 * Check whether cookie exists
 * @param name cookie key/name
 * @returns true if exists else false
 */
export function cookieExists(name: string): boolean {
	for (var cookiePair of document.cookie.split(";")) {
		var cookie = cookiePair.split("=");
		if (name === cookie[0].trim()) {
			return true;
		}
	}
	return false;
}

/**
 * Create a new cookie
 * @param name cookie name
 * @param value cookie value
 * @param expiryDate cookie expiration date
 * @param domain cookie domain
 */
export function createCookie(name: string, value: string, expiryDate: string, domain?: string): void {
	var newCookie = `${name}=${value}; expires=${expiryDate}; path=/`;
	if (domain) {
		newCookie += `; domain=${domain}; SameSite=None; Secure=true`;
	}
	document.cookie = newCookie;
}

/**
 * Create a new cookie
 * @param name cookie name
 * @param value cookie value
 * @param expiryDate cookie expiration date
 * @param domain cookie domain
 */
export function createCookieIfNotExists(
	name: string,
	value: string,
	expiryDate: string,
	domain?: string,
): void {
	if (!cookieExists(name)) {
		createCookie(name, value, expiryDate, domain);
	}
}

/**
 * Get whether current page is localhost
 */
export function isLocalhost(): boolean {
	return window.location.hostname === "localhost";
}

/**
 * Navigate to URL and reload from server (vs navigating from within loaded React SPA)
 * @param path relative path to navigate to
 */
export function navigateAndReload(path: string): void {
	if (!path.startsWith("/")) {
		path = "/" + path;
	}
	window.location.href = process.env.REACT_APP_DEV_URL_PREFIX + path;
}

export const isInCustomerModeCookie: string = "VS_isInCustomerMode";

export function userIsInCustomerMode(): boolean {
	if (cookieExists(isInCustomerModeCookie)) {
		return getCookieValue(isInCustomerModeCookie) === "true";
	} else {
		return false;
	}
}

//#endregion

/**
 * @deprecated - This function does nothing meaningful since JavaScript strings are falsey if null, undefined or empty string anyway.
 *
 * It can either be removed or replaced with isNullOrWhitespace
 *
 * checks if string is null, undefined, or empty
 * @param e string input
 * @returns true if string is Null, undefined, or empty, else false
 */
export function isNullOrEmpty(e: string | null | undefined): boolean {
	return !e || e.length === 0;
}

/**
 * checks if string is null, undefined, empty, or whitespace
 * @param e string input
 * @returns true if string is Null, undefined, or empty, else false
 */
export function isNullOrWhitespace(e: string | null | undefined): boolean {
	return !e || e.trim().length === 0;
}

/**
 * Get friendly name for install status
 * @param status install status
 * @returns
 */
export function installStatusName(status: InstallStatus | null): string {
	if (status === null) {
		return "None";
	}
	switch (status) {
		case InstallStatus.NotStarted:
			return "Not started";
		case InstallStatus.InProgress:
			return "In Progress";
		case InstallStatus.Live:
			return "Live";
		case InstallStatus.Deactivated:
			return "Deactivated";
		default:
			return "Unknown";
	}
}

const DontCapitalizeInTitle = new Set([
	"and",
	"as",
	"at",
	"but",
	"by",
	"for",
	"from",
	"if",
	"in",
	"into",
	"like",
	"near",
	"nor",
	"of",
	"off",
	"on",
	"once",
	"onto",
	"or",
	"over",
	"past",
	"so",
	"than",
	"that",
	"till",
	"to",
	"up",
	"upon",
	"with",
	"when",
	"yet",
]);

/** Capitalize one word */
function capitalize(word: string, index: number = 0): string {
	if (index !== 0 && DontCapitalizeInTitle.has(word.toLowerCase())) {
		return word;
	}
	return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * Capitalize the first letter of word(s)
 * @param text text to capitalize
 * @param allWords whether to capitalized the first letter of all words (true) or just the first word (false)
 * @returns
 */
export function titleCase(text: string, allWords: boolean = true) {
	if (allWords && text.indexOf(" ") !== -1) {
		return text.split(" ").map(capitalize).join(" ");
	} else {
		return capitalize(text);
	}
}

//#region crypto

/**
 * Generates a random client secret
 * @returns base64 encoded secret with 64 bytes
 */
export function generateRandomSecret(): string {
	const array = new Uint8Array(64);
	window.crypto.getRandomValues(array);
	return bufferToBase64(array);
}

/**
 * SHA 1 hash a string
 * @param plainText plain text to hash
 * @returns hashed string
 */
export async function sha1Hash(plainText: string): Promise<string> {
	const hashed: Promise<ArrayBuffer> = window.crypto.subtle.digest(
		{ name: "SHA-1" },
		convertStringToArrayBufferView(plainText),
	);

	return convertArrayBufferToHexaDecimal(await hashed).toUpperCase();
}

/**
 * SHA 256 hash a string
 * @param plainText plain text to hash
 * @returns hashed string
 */
export async function sha256hash(plainText: string): Promise<string> {
	const hashed: Promise<ArrayBuffer> = window.crypto.subtle.digest(
		{ name: "SHA-256" },
		convertStringToArrayBufferView(plainText),
	);

	return convertArrayBufferToHexaDecimal(await hashed).toUpperCase();
}

/**
 * Converts Array Buffer to hexadecimal String
 * @param buffer
 * @returns
 */
function convertArrayBufferToHexaDecimal(buffer: ArrayBufferLike): string {
	const data_view = new DataView(buffer);
	let i,
		hex = "",
		c;

	for (i = 0; i < data_view.byteLength; i += 1) {
		c = data_view.getUint8(i).toString(16);
		if (c.length < 2) c = "0" + c;

		hex += c;
	}

	return hex;
}

/**
 * Converts string to Uint8 Array
 * @param str
 * @returns
 */
function convertStringToArrayBufferView(str: string): Uint8Array {
	const bytes = new Uint8Array(str.length);
	for (let i = 0; i < str.length; i++) bytes[i] = str.charCodeAt(i);
	return bytes;
}

/**
 * Converts Uint8 Array to string
 * @param buf
 * @returns
 */
function bufferToBase64(buf: Uint8Array): string {
	const binstr = Array.prototype.map
		.call(buf, function (ch) {
			return String.fromCharCode(ch);
		})
		.join("");
	return btoa(binstr);
}

//#endregion

//#region array helpers

/**
 * Checks whether two arrays are equal in that they contain the same elements, but not necessarily in the same order.
 * Will likely only work if the arrays contain primitive values like numbers or strings.
 * @param arr1 first array
 * @param arr2 second array
 * @returns true if equal, else false
 */
export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
	if (arr1.length !== arr2.length) return false;
	for (const val of arr1) {
		if (!arr2.includes(val)) return false;
	}
	for (const val of arr2) {
		if (!arr1.includes(val)) return false;
	}
	return true;
}
/**
 * Concatenates 2 arrays and removes duplicates
 * @param arr1 first array
 * @param arr2 second array
 * @param mapper mapping function to allow comparing based on a specific property in an object
 * @returns concatenated array with duplicates removed
 */
export function concatDistinct<TItem, TCompareVal>(
	arr1: TItem[],
	arr2: TItem[],
	mapper?: (item: TItem) => TCompareVal,
): TItem[] {
	let finalArray = [...arr1];
	let mappedArray = mapper ? arr1.map(mapper) : [];
	for (const val of arr2) {
		if (mapper) {
			const mappedVal = mapper(val);
			if (!mappedArray.includes(mappedVal)) {
				finalArray.push(val);
				mappedArray.push(mappedVal);
			}
		} else if (!finalArray.includes(val)) {
			finalArray.push(val);
		}
	}
	return finalArray;
}

//#endregion

//#region Google Analytics

/**
 * Get whether current user is opted in for Google Analytics tracking
 */

export const gACookieName: string = "AOCookieForGA";

export function gaOptIn(): boolean {
	return getCookieValue(gACookieName) === "true";
}

/**
 * Whether Google Analytics tracking is enabled
 */
export function isGaEnabled() {
	return !isLocalhost() && gaOptIn();
}

/**
 * Initialize Google Analytics
 * @param trackingCode Tracking code assigned by Google
 */
export function gaInitialize(trackingCode: string) {
	if (isGaEnabled() && trackingCode) {
		ReactGA.initialize(trackingCode);
	}
}

/**
 * Trigger Google Analytics event
 * @param args args to pass to the event
 */
export function gaEvent(args: ReactGA.EventArgs) {
	if (isGaEnabled()) {
		ReactGA.event(args);
	}
}

//#endregion

//#region userSecurity
/**
 * Check whether user has equivalent or greater security
 * @param userSecurity user's security
 * @param accountType account type to check for
 * @returns
 */
export function hasSecurity(userSecurity: IUserSecurity, accountType: UserAccountType) {
	return userSecurity.isSuperAdministrator || userSecurity.userAccountType >= accountType;
}
//#endregion

//#region string operations
/**
 * Prettify pascal case string (split). A space will not be added between consecutive upper case characters.
 * @param needsSpace Pascal cased string
 * @returns string with spaces
 */
export function prettifyPascalCase(needsSpace: string): string {
	return needsSpace.replace(/([^A-Z])([A-Z])/g, "$1 $2").trim();
}

/**
 * Pluralize a unit if its value !== 1
 * @param value numeric value
 * @param unit unit to pluralize if needed
 * @returns
 */
export function pluralIfNeeded(unit: string, value: number): string {
	return `${unit}${value === 1 ? "" : "s"}`;
}
//#endregion

export function isEmailValid(email: string): boolean {
	const pattern1 = new RegExp(
		"^(([0-9a-z]*((\\.(?!\\.))|[-!#\\$%&'\\*\\+\\/=\\?\\^`\\{\\}\\|~\\w])*)([0-9a-z])@)(([0-9a-z][-\\w]*[0-9a-z]*\\.)+[a-z0-9][\\-a-z0-9]{0,62}[a-z0-9])$",
		"i",
	);
	const pattern2 = new RegExp(
		"^(([0-9a-z]*((\\.(?!\\.))|[-!#\\$%&'\\*\\+\\/=\\?\\^`\\{\\}\\|~\\w])*)([0-9a-z])@(\\[(\\d{1,3}\\.){3}\\d{1,3}\\]))$",
		"i",
	);
	if (!pattern1.test(email) && !pattern2.test(email)) {
		return false;
	}
	const [local, domain] = email.split("@");
	if (local.length > 64 || domain.length > 190) {
		return false;
	}

	return true;
}

//#region date/time functions

export function hoursFromNow(hours: number): Date {
	const date = new Date();
	date.setHours(date.getHours() + hours);
	return date;
}

export function yearsFromNow(years: number): Date {
	const date = new Date();
	date.setFullYear(date.getFullYear() + years);
	return date;
}

/**
 * Get date only part (without time) from JS date
 * @param dt date
 * @returns string with date only part
 */
export function getDatePart(dt: Date | undefined): string {
	if (dt) {
		return dt.toJSON().split("T")[0];
	}
	return "";
}

/**
 * Get the date in the form of yyyy-mm-dd without one-off error when converting to ISO
 */
export function getDate(dt: Date | undefined): string {
	if (dt) {
		const year = dt.getFullYear();
		const month = dt.getMonth() + 1;
		const date = dt.getDate();
		let monthString = month.toString();
		let dateString = date.toString();

		if (month < 10) {
			monthString = "0" + monthString;
		}

		if (date < 10) {
			dateString = "0" + dateString;
		}

		return year + "-" + monthString + "-" + dateString;
	}
	return "";
}

/**
 * Converts a date string in the form of yyyy-mm-dd to the locale date string
 * @param dateString date string in the form of yyyy-mm-dd
 * @returns locale date string, "" if invalid
 */
export function displayDate(dateString: string): string {
	if (!dateString) return "";
	const [YYYY, MM, DD] = dateString.split("-");
	const dateValue = new Date(parseInt(YYYY), parseInt(MM) - 1, parseInt(DD));
	if (!dateValue) return "";
	return dateValue.toLocaleDateString();
}

/**
 * Fixes the date parsed by yup incorrectly https://github.com/jquense/yup/issues/1919
 * Ensures 00XX years are actually 00XX, instead of 19XX
 * @param dateString date string in the form of yyyy-mm-dd
 * @param defaultDate Date if date is not fixed
 * @returns new Date object if fixed, defaultDate otherwise
 */
export function fixDate(dateString: string, defaultDate: Date): Date {
	if (!dateString) return defaultDate;
	if (dateString.startsWith("00")) {
		return new Date("00" + dateString.slice(2));
	}
	return defaultDate;
}

/**
 * Gets the number of from a start date to an end date, assuming time moves forward
 * @param start date object to start at
 * @param end date object to end at
 * @returns number of days from the start date to the end date
 */

export const getDaysDiff = (start: Date, end: Date): number => {
	return (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24); // divide by milliseconds in a day
};

/**
 * Compares two dates and ignores timezone offsets
 * @param first string representing a date in yyyy-MM-dd form
 * @param second string representing a date in yyyy-MM-dd form
 * @returns -1 if the first date is before the second, 0 if they are equal, 1 if the first date is after the second, and 2 if there was an unexpected error
 */

export const compareDateStrings = (first: string, second: string): number => {
	if (!first || !second) {
		return 2;
	}

	const dateOne = new Date(first).getTime();
	const dateTwo = new Date(second).getTime();

	if (dateOne < dateTwo) {
		return -1;
	} else if (dateOne > dateTwo) {
		return 1;
	} else if (dateOne === dateTwo) {
		return 0;
	}
	return 2;
};

//#endregion

/**
 * Gets value of URL search parameter with case-insensitive key
 * @param {URLSearchParams} object
 * @param {string} key
 * @return value of object
 */
export function getCaseInsensitiveParam(object: URLSearchParams, key: string): any {
	let paramValue = null;
	object.forEach((value, string) => {
		if (string.toUpperCase() === key.toUpperCase()) {
			paramValue = value;
		}
	});

	return paramValue;
}

/**
 * Get html encoded string
 * @param {string} textToEncode
 * @return encoded string
 */
export function getHtmlEncodedString(textToEncode: string): string {
	if (!textToEncode || textToEncode.length === 0) {
		return "";
	}

	let textArea = document.createElement("textarea");
	textArea.textContent = textToEncode;
	return textArea.innerHTML;
}

/**
 * Get html decoded string
 * @param {string} textToDecode
 * @return decoded string
 */
export function getHtmlDecodedString(textToDecode: string): string {
	if (!textToDecode || textToDecode.length === 0) {
		return "";
	}

	let textArea = document.createElement("textarea");
	textArea.innerHTML = textToDecode;
	return textArea.value;
}

/**
 * Get text from a string that contains html. Only use on trusted values.
 * @param {string} htmlToParse
 * @return text string from HTML
 */
export function dangerouslyGetTextFromHtml(htmlToParse: string): string {
	if (!htmlToParse || htmlToParse.length === 0) {
		return "";
	}

	const dummyElement = document.createElement("div");
	dummyElement.innerHTML = htmlToParse;

	return dummyElement.textContent || "";
}

/**
 * Parse the query string to a map of key:value
 * @param query query string
 */
export function parseQueryString(query: string): Map<string, string> {
	const map = new Map<string, string>();
	if (query.startsWith("?")) query = query.substr(1);
	const pieces = query.split("&");
	for (const piece of pieces) {
		const [key, value] = piece.split("=");
		map.set(key, value);
	}
	return map;
}

/**
 * Get user friendly name for Organization Status
 * @param status enum value
 * @returns friendly string
 */
export function getStringForOrganizationStatus(status: OrganizationStatus): string {
	switch (status) {
		case OrganizationStatus.Undefined:
		default:
			return "Undefined";
		case OrganizationStatus.Interested:
			return "Interested";
		case OrganizationStatus.OrganizationDenied:
			return "Interest Denied";
		case OrganizationStatus.OrganizationApproved:
			return "Interest Approved";
		case OrganizationStatus.RequestingProgramLevel:
			return "Requesting Program Level";
		case OrganizationStatus.ProgramLevelDenied:
			return "Program Level Denied";
		case OrganizationStatus.Invoicing:
			return "Invoicing";
		case OrganizationStatus.PaymentFailed:
			return "Payment Failed";
		case OrganizationStatus.ProgramLevelApproved:
			return "Approved";
		case OrganizationStatus.VendorDiscontinued:
			return "Vendor Discontinued";
	}
}

/**
 * Get user friendly name for Program Level
 * @param programLevel enum value
 * @returns friendly string
 */
export function getStringForProgramLevel(programLevel: ProgramLevel): string {
	switch (programLevel) {
		case ProgramLevel.Undefined:
		default:
			return "Undefined";
		case ProgramLevel.USCDIMember:
			return "Epic on FHIR";
		case ProgramLevel.BronzeMinus:
			return "Bronze Minus";
		case ProgramLevel.Greenhouse:
			return "Greenhouse";
		case ProgramLevel.Garden:
			return "Garden";
		case ProgramLevel.Terrace:
			return "Terrace";
		case ProgramLevel.EpicCommunityMember:
			return "Customer";
		case ProgramLevel.Epic:
			return "Epic";
	}
}

/**
 * Get organization type Epic, Customer or Vendor using Program Level
 * @param programLevel enum value
 * @returns friendly string
 */
export function getOrganizationTypeFromProgramLevel(programLevel: ProgramLevel): string {
	switch (programLevel) {
		case ProgramLevel.Undefined:
			return "";
		case ProgramLevel.EpicCommunityMember:
			return "Customer";
		case ProgramLevel.Epic:
			return "Epic";
		default:
			return "Vendor";
	}
}

/**
 * Get user friendly name for User Account Type
 * @param status enum value
 * @returns friendly string
 */
export function getStringForUserAccountType(accountType: UserAccountType): string {
	switch (accountType) {
		case UserAccountType.Undefined:
		default:
			return "Undefined";
		case UserAccountType.QueuedForAdminApproval:
			return "Queued";
		case UserAccountType.PendingApproval:
			return "Pending";
		case UserAccountType.VendorDeveloper:
			return "Vendor Developer";
		case UserAccountType.EpicUser:
			return "Epic User";
		case UserAccountType.VendorAdmin:
			return "Vendor Admin";
		case UserAccountType.EpicAdmin:
			return "Epic Admin";
		case UserAccountType.SuperAdministrator:
			return "Super Administrator";
	}
}

/**
 * Get user friendly name for Organization Association
 * @param status enum value
 * @returns friendly string
 */
export function getStringForOrganizationAssociation(associationType: OrganizationUserAssociation): string {
	switch (associationType) {
		case OrganizationUserAssociation.Undefined:
		default:
			return "Undefined";
		case OrganizationUserAssociation.InitialContact:
			return "Initial Contact";
		case OrganizationUserAssociation.EpicOwner:
			return "Epic Owner";
		case OrganizationUserAssociation.DirectEmployee:
			return "Direct Employee";
		case OrganizationUserAssociation.RemoteAccess:
			return "Remote Access";
		case OrganizationUserAssociation.EpicSupport:
			return "Epic Support";
	}
}

/**
 * Get user friendly name for Term Type
 * @param termType enum value
 * @returns friendly string
 */
export function getStringForTermType(termType: TermsType): string {
	switch (termType) {
		case TermsType.Undefined:
		default:
			return "All Agreements";
		case TermsType.AppDeveloperAgreement:
			return "Developer Agreement";
		case TermsType.AppCustomerAgreement:
			return "Customer Agreement";
		case TermsType.AppMarketProgramDetails:
			return "Program Details";
		case TermsType.AppAgreement:
			return "App Agreement";
		case TermsType.USCDIonFHIR:
			return "open.Epic Terms of Use";
		case TermsType.APILicenseAgreement:
			return "open.epic API Subscription Agreement";
		case TermsType.ConnectionHubAgreement:
			return "Connection Hub Listing Agreement";
		case TermsType.PrivateAPIAccess:
			return "Private API Agreement";
		case TermsType.ToolboxAgreement:
			return "Toolbox Agreement";
	}
}

/**
 * Get user friendly name for Yes No Filter
 * @param termType enum value
 * @returns friendly string
 */
export function getStringForYesNoFilter(yesNoFilterOption: YesNoFilterOptions): string {
	switch (yesNoFilterOption) {
		case YesNoFilterOptions.No:
			return "No";
		case YesNoFilterOptions.Yes:
			return "Yes";
		default:
			return "";
	}
}

/**
 * Get user friendly name for Term Type
 * @param termType enum value
 * @returns friendly string
 */
export function getURLForTermType(termType: TermsType): string {
	switch (termType) {
		case TermsType.Undefined:
		default:
			return "";
		case TermsType.AppDeveloperAgreement:
			return "Developer/VendorServicesDeveloperAgreement?termsId=";
		case TermsType.AppCustomerAgreement:
			return "Gallery/VendorServicesCustomerAgreement?termsId=";
		case TermsType.AppAgreement:
			return "Developer/AppAgreement?termId=";
		case TermsType.USCDIonFHIR:
			return "Developer/OpenEpicAgreement?termId=";
		case TermsType.APILicenseAgreement:
			return "Gallery/ApiLicenseAgreement?termsId=";
		case TermsType.ConnectionHubAgreement:
			return "Developer/ConnectionHubListingAgreement?termId=";
		case TermsType.PrivateAPIAccess:
			return "Resources/PrivateAPIAgreement?termsId=";
		case TermsType.ToolboxAgreement:
			return "Resources/ToolboxAgreement?termsId=";
	}
}

/**
 * Check whether the list of menus contains a given menu ID, searching in submenus if present
 * @param menus menus to search
 * @param menuIdToFind menu to find
 * @returns true if found
 */
export function hasMenu(menus: ParentMenu[], menuIdToFind: number) {
	for (const menu of menus) {
		if (menu.id === menuIdToFind) return true;
		if (menu.children.length > 0 && menu.children.some((m) => m.id === menuIdToFind)) return true;
	}
	return false;
}

/**
 * Determines whether two strings are equivalent, sorts empty string to end of the list
 * @param a
 * @param b
 * @returns
 */
export function localeCompareIgnoreEmpty(a: string, b: string): number {
	if (isNullOrEmpty(a)) return 1;
	if (isNullOrEmpty(b)) return -1;
	if (a === b) return 0;
	return a.localeCompare(b);
}

export function sortDate(a: Date | null, b: Date | null): number {
	const date1 = a ? new Date(a).getTime() : 0;
	const date2 = b ? new Date(b).getTime() : 0;

	if (date1 === 0 && date2 === 0) {
		return 0;
	} else {
		return date1 - date2;
	}
}

/**
 * Gets the current instant used for the file name in a record export
 * @returns current instant as a string
 */
export function getInstantForRecordExport(): string {
	return new Date()
		.toISOString()
		.replace(/[^0-9]/g, "")
		.slice(0, -3);
}
