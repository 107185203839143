/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Button for customer to agree to API license terms.
 * Assumption is that the caller will only show this button if the org hasn't agreed to the current terms
 * @module Epic.AppOrchard.Core.ApiLicenseTermsButton
 */

import { As, Button, ButtonProps, useToast } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { acceptApiLicenseTerms } from "ao/data";
import { getBaseUrl } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { ChangeEvent, FC, memo, useCallback, useMemo, useState } from "react";
import { FaFileContract } from "react-icons/fa";
import { AgreementModal, StatusModal } from ".";

interface IProps {
	/** current open.epic API license terms ID */
	apiLicenseAgreementId: number;
	/** whether the org signed a previous version of the terms */
	orgSignedPreviousTerms: boolean;
	successHandler: () => void;
	/** button-like component to render the button as */
	buttonAs?: As;
}

type Props = IProps & ButtonProps;

/** Button for customer to agree to API license terms.
    Assumption is that the caller will only show this button if the org hasn't agreed to the current terms  */
export const ApiLicenseTermsButton: FC<Props> = memo((props: Props) => {
	const { apiLicenseAgreementId, orgSignedPreviousTerms, successHandler, buttonAs, ...rest } = props;

	//#region state/handlers
	const baseUrl = useMemo(() => getBaseUrl(), []);
	const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false);
	const [acceptChecked, setAcceptedChecked] = useState<boolean>(false);
	const [hasErrorSaving, setHasErrorSaving] = useState<boolean>(false);
	const handleToggleHasError = useCallback((showModal: boolean) => () => setHasErrorSaving(showModal), []);

	const agreementUrl = `${baseUrl}Gallery/ApiLicenseAgreement?termsId=${apiLicenseAgreementId}`;
	const agreementDownloadUrl = `${baseUrl}Gallery/ApiLicenseAgreement?download=true&termsId=${apiLicenseAgreementId}`;

	const handleAgreementModalClosed = useCallback(() => {
		setAcceptedChecked(false);
		setShowAgreementModal(false);
	}, []);

	const handleAcceptChecked = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => setAcceptedChecked(ev.target.checked),
		[],
	);

	const toast = useToast();
	const handleAgreeToTermsSuccess = useCallback(() => {
		toast({
			title: "Agreed to Terms",
			description: "Agreed to open.epic API Subscription Terms",
			status: "success",
			duration: config.ToastDuration,
			isClosable: true,
		});
		successHandler();
	}, [successHandler, toast]);

	const [acceptingTermsState, acceptTerms] = useAsync(acceptApiLicenseTerms, {
		executeImmediately: false,
		displayName: "acceptApiLicenseTerms",
		onSuccess: () => handleAgreeToTermsSuccess(),
		onFailure: () => handleToggleHasError(true)(),
	});

	const handleAcceptTerms = useCallback(() => {
		acceptTerms(apiLicenseAgreementId);
	}, [apiLicenseAgreementId, acceptTerms]);

	const handleTermsAccepted = useCallback(() => {
		handleAgreementModalClosed();
		handleAcceptTerms();
	}, [handleAcceptTerms, handleAgreementModalClosed]);

	const handleRequestClicked = useCallback(() => setShowAgreementModal(true), []);

	const isSaving = isInFlight(acceptingTermsState);
	const helptext = orgSignedPreviousTerms
		? "Review the updates to the open.epic API Subscription Agreement."
		: "Review and agree to the open.epic API Subscription Agreement";
	//#endregion

	const ApiLicenseButton = buttonAs || Button;

	return (
		<>
			<ApiLicenseButton
				colorScheme="blue"
				title={helptext}
				aria-label={helptext}
				leftIcon={<FaFileContract size={20} />}
				onClick={handleRequestClicked}
				disabled={isSaving}
				isLoading={isSaving}
				loadingText="Saving"
				{...rest}
			>
				{orgSignedPreviousTerms ? "Review Updates" : "Review & Accept"}
			</ApiLicenseButton>
			<AgreementModal
				isOpen={showAgreementModal}
				header="open.epic API Subscription Agreement"
				acceptChecked={acceptChecked}
				termsUrl={agreementUrl}
				downloadTermsUrl={agreementDownloadUrl}
				termsAriaLabel="open.epic API Subscription Agreement Terms"
				acceptCaption="Accept"
				cancelCaption="Cancel"
				onClose={handleAgreementModalClosed}
				onAgree={handleTermsAccepted}
				additionalDetails={
					orgSignedPreviousTerms
						? "Your organization has previously accepted these terms and any updates to these terms apply to your organization in accordance with the agreement. Click 'Accept' to acknowledge the update and continue."
						: ""
				}
				onAcceptCheckedChanged={handleAcceptChecked}
			/>
			<StatusModal
				title="Error agreeing to terms"
				message="Error agreeing to the open.epic API license agreement terms. Please try again later or contact your Epic representative if this continues to happen."
				isOpen={hasErrorSaving}
				onClose={handleToggleHasError(false)}
				status="error"
			/>
		</>
	);
});
