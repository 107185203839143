/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Upload JWT public key from an X.509 cert file. Also shows whether public key upload is even needed
 * (e.g. if the app has a JKU no public key is needed but one can be supplied)
 * @module Epic.AppOrchard.Core.JwtPublicKeyUpload
 */

import { Box, Button, Spacer, Wrap } from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { validateJWTSigningKey } from "ao/data";
import { EnvironmentType, IJwtSigningKeyValidationResponse } from "ao/data/types";
import { getBaseUrl, isNullOrEmpty } from "ao/utils/helpers";
import React, { FC, memo, useCallback, useState } from "react";
import { AOLink as Link, ConfirmationModal, FileUpload, StatusAlert } from "..";

interface IProps {
	/** The customer's current Epic version */
	customerEpicVersion?: number;
	customerName?: string;
	/** whether the app is a customer app created to use with their own environment */
	isOwnApp: boolean;
	/** JKU for environment */
	appJsonWebKeySetUrl: string | null;
	/** called when user choses to continue with the uploaded key (or with no key if JKU is available) */
	onContinueClicked: (publicKeyBlob: string) => void;
	/** caption of the activate/continue button */
	continueButtonCaption: string;
	/** called when the workflow is cancelled */
	onCancelClicked: () => void;
	/** whether currently saving so a loading indicator can be shown */
	isSaving?: boolean;
	envType: EnvironmentType;
	/** skips the need for clicking the continue and cancel button. Uploading the file will automatically
	 * trigger the continue handler and remove the file with trigger the onCancel handler*/
	skipConfirmation?: boolean;
	/** get keyBlob from parent. */
	providedThumbprint?: string;
	/** hides header text */
	hideHeader?: boolean;
	/** show public key warning for client credentials */
	showPublicKeyWarning?: boolean;
}

/** Upload JWT public key from an X.509 cert file. Also shows whether public key upload is even needed
 * (e.g. if the app has a JKU no public key is needed but one can be supplied) */
export const JwtPublicKeyUpload: FC<IProps> = memo((props: IProps) => {
	const {
		onContinueClicked,
		continueButtonCaption,
		onCancelClicked,
		isSaving,
		appJsonWebKeySetUrl,
		customerEpicVersion,
		isOwnApp,
		customerName,
		envType,
		skipConfirmation,
		providedThumbprint,
		hideHeader,
		showPublicKeyWarning,
	} = props;

	//#region state and handlers

	const hasJku = !isNullOrEmpty(appJsonWebKeySetUrl);
	const hasUsableJku = hasJku && (!customerEpicVersion || customerEpicVersion >= config.MinEpicJkuVersion);
	const envText = envType === EnvironmentType.NonProd ? "Non-Production" : "Production";

	const [publicKeyBlob, setPublicKeyBlob] = useState<string>("");
	const [showPublicKeyErrorModal, setShowPublicKeyErrorModal] = useState<boolean>(false);

	const handleLoaded = useCallback(
		(_contents: string, validationResponse: IJwtSigningKeyValidationResponse) => {
			if (skipConfirmation) {
				onContinueClicked(validationResponse.jwtSigningKeyBlob);
			} else {
				setPublicKeyBlob(validationResponse.jwtSigningKeyBlob);
			}
		},
		[onContinueClicked, skipConfirmation],
	);

	const handleRemoveFileClicked = useCallback(() => {
		if (skipConfirmation) {
			onCancelClicked();
		}
	}, [onCancelClicked, skipConfirmation]);

	const handleContinueClicked = useCallback(() => {
		onContinueClicked(publicKeyBlob);
	}, [onContinueClicked, publicKeyBlob]);

	const handleCancelClicked = useCallback(() => {
		onCancelClicked();
		setPublicKeyBlob("");
	}, [onCancelClicked]);

	const handleToggleErrorModal = useCallback((show: boolean) => () => setShowPublicKeyErrorModal(show), []);
	const oauth2GuideLink = getBaseUrl() + "Article/Index?docId=oauth2";
	const jwksGuideLink = getBaseUrl() + "Article/Index?docId=oauth2&section=JWKS";
	const createKeyPairGuideLink = oauth2GuideLink + "&section=Creating-Key-Pair";

	let header = "";
	if (!hasJku) {
		header += `The app does not have a JSON Web Key Set (JWKS) URL registered for ${envText} for public key retrieval, so you need to upload an X.509 certificate containing the public key.`;
	} else if (hasJku && !hasUsableJku) {
		header += `The app has a JSON Web Key Set (JWKS) URL registered for ${envText} for public key retrieval. However, ${
			isOwnApp ? "your " : customerName + "'s "
		} system is not on an Epic version that supports using a JWKS URL to validate JWTs ${
			isOwnApp ? " (JWKS URL support was added in the May 2021 version)" : ""
		}. So you need to upload an X.509 certificate containing the public key.`;
	} else if (hasUsableJku) {
		header += `The app has a JSON Web Key Set (JWKS) URL registered for ${envText} for public key retrieval. This endpoint will be used to retrieve public keys for JWT validation. Additionally, you can upload an X.509 certificate containing the public key.`;
	}

	//#endregion

	return (
		<>
			{!hideHeader && (
				<Box mb="1em">
					<Box mb="1em">
						In order to use JSON Web Token (JWT) authentication, a public key must exist in the
						environment to validate the JWT signature.
					</Box>
					<Box mb="1em">{header}</Box>
					<Link url={oauth2GuideLink} target="_blank">
						View documentation on OAuth 2.0
					</Link>
					{showPublicKeyWarning && (
						<StatusAlert
							mt="5px"
							message={
								<Box>
									Your backend app will need to support a JWK Set URL (JKU). Customers on
									Epic version November 2024 and beyond can require that backend apps use
									JKUs for communicating public keys. When enabled, static public keys will
									no longer work on those apps. You can find information on JKUs{" "}
									<Link url={jwksGuideLink} target="_blank">
										here
									</Link>
									.
								</Box>
							}
							status="warning"
						/>
					)}
				</Box>
			)}
			<Box mb="1em">
				<FileUpload
					uploadCaption="Upload public key"
					onFileLoaded={handleLoaded}
					onUploadAsyncValidator={validateJWTSigningKey}
					onValidationFailure={handleToggleErrorModal(true)}
					onFileRemoved={handleRemoveFileClicked}
					providedFileName={providedThumbprint || undefined}
				></FileUpload>
			</Box>
			{!skipConfirmation && (
				<Wrap>
					<Spacer />
					<Button
						onClick={handleContinueClicked}
						colorScheme="green"
						mr="2"
						isLoading={isSaving}
						loadingText="Saving"
						disabled={!hasUsableJku && !publicKeyBlob}
					>
						{continueButtonCaption}
					</Button>
					<Button onClick={handleCancelClicked} disabled={isSaving}>
						Cancel
					</Button>{" "}
				</Wrap>
			)}
			<ConfirmationModal
				message={
					<>
						<Box mb="1em">
							There was a problem uploading the JWT signing public key file you provided. Please
							make sure it's a base64 encoded X.509 public key certificate and that the key
							length is at least 2048 bits.
						</Box>
						<Box>
							See the{" "}
							<Link url={createKeyPairGuideLink} target="_blank">
								Creating a Public Private Key Pair for JWT Signature tutorial
							</Link>{" "}
							for more information on how to create X.509 public key certificates.
						</Box>
					</>
				}
				title="Invalid Public Key File"
				isOpen={showPublicKeyErrorModal}
				onAccept={handleToggleErrorModal(false)}
			/>
		</>
	);
});
