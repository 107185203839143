/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file NewVersionLink for widgets
 * @module Epic.AppOrchard.Dashboard.NewVersionLink
 */
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { getBaseUrl } from "ao/utils/helpers";
import React, { FC, memo, useMemo } from "react";
import { BiSort } from "react-icons/bi";
import { GiNewShoot } from "react-icons/gi";
import { AOLink as Link } from "..";

interface IProps {
	appId: number;
	newestAppId: number;
	newVersionUrl: string;
	showWarningIcon?: boolean;
	/** whether this app belongs to the user viewing the app */
	isOwnApp?: boolean;
}

/** NewVersionLink component for widgets */
export const NewVersionLink: FC<IProps> = memo((props: IProps) => {
	const { appId, newestAppId, newVersionUrl, showWarningIcon, isOwnApp } = props;
	const baseUrl = useMemo(() => getBaseUrl(), []);
	const title = "There is a new version of this app. Click to see the latest version of this app.";

	const compareUrl = `${baseUrl}Developer/VersionCompare?appId=${newestAppId}&otherVersionId=${appId}`;
	return (
		<>
			{newVersionUrl && (
				<Link url={newVersionUrl} title={title} target="_blank">
					{showWarningIcon ? (
						<WarningTwoIcon w={4} h={4} mr="4px" color="yellow.500" />
					) : (
						<GiNewShoot style={{ display: "inline-block", marginRight: "4px" }} color="green" />
					)}
					{isOwnApp ? "Newest Version" : "New Version Available"}
				</Link>
			)}
			{!newVersionUrl && (
				<Box>
					{showWarningIcon ? (
						<WarningTwoIcon w={4} h={4} mr="4px" color="yellow.500" />
					) : (
						<GiNewShoot style={{ display: "inline-block", marginRight: "4px" }} color="green" />
					)}
					{isOwnApp ? "Newest Version" : "New Version Available"}
				</Box>
			)}
			<Link
				url={compareUrl}
				title="Compare this app version to the newest version of this app."
				target="_blank"
			>
				<BiSort
					style={{ display: "inline-block", transform: "rotate( 90deg )", marginRight: "4px" }}
					color="blue"
				/>
				Compare Versions
			</Link>
		</>
	);
});
