/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Toggle between remote organizations
 * @module Epic.AppOrchard.Frame.RemoteOrgSelection
 */

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { remoteOrganizations, switchOrganization } from "ao/data";
import { IRemoteOrg } from "ao/types";
import { navigateAndReload } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { StatusModal } from "../Core";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	stayOnCurrentPage?: boolean;
}

/**
 * Toggle between remote organizations
 */
export const RemoteOrgSelection: FC<IProps> = memo((props) => {
	const { isOpen, onClose, stayOnCurrentPage } = props;

	//#region state/hooks
	const [hasLoaded, setHasLoaded] = useState(false);
	const [remoteOrgs, setRemoteOrgs] = useState<IRemoteOrg[]>([]);
	const [selectedOrgId, setSelectedOrgId] = useState<number | undefined>();
	const [currentOrgId, setCurrentOrgId] = useState<number | undefined>();
	const [error, setError] = useState("");

	const [loadingState, loadRemoteOrganizations] = useAsync(remoteOrganizations, {
		executeImmediately: false,
		displayName: "remoteOrganizations",
		onSuccess: (response) => {
			setRemoteOrgs(response.Data.availableOrgs);
			setSelectedOrgId(response.Data.currentOrg);
			setCurrentOrgId(response.Data.currentOrg);
			setHasLoaded(true);
		},
		onFailure: () => setError("Error loading remote organizations."),
	});

	const [savingState, saveRemoteOrgChange] = useAsync(switchOrganization, {
		executeImmediately: false,
		displayName: "switchOrganization",
		onSuccess: (response) => {
			if (!response.Data.Success) {
				setError("Error switching organizations.");
				return;
			}

			if (stayOnCurrentPage) {
				window.location.reload();
			} else {
				// go to the home page (current page might not be accessible after switching orgs)
				// and reload it from the server to get updated user security
				navigateAndReload("Home");
			}
		},
		onFailure: () => setError("Error switching organizations."),
	});
	const isLoading = isInFlight(loadingState) || isInFlight(savingState);

	useEffect(() => {
		if (!hasLoaded && isOpen) {
			loadRemoteOrganizations();
		}
	}, [hasLoaded, loadRemoteOrganizations, isOpen]);

	const handleOrgChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedOrgId(parseInt(ev.target.value));
	}, []);

	const handleConfirmClicked = useCallback(() => {
		if (selectedOrgId === currentOrgId) {
			onClose();
			return;
		}
		saveRemoteOrgChange(selectedOrgId!);
	}, [currentOrgId, onClose, saveRemoteOrgChange, selectedOrgId]);

	//#endregion

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="2xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Switch Organization</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box mb="1em">
							You can manage other organizations/regions that you have authorized remote access
							to.
						</Box>
						<FormControl id="SelectOrg" title="Select a remote organization to switch to">
							<FormLabel>Select Organization/Region</FormLabel>
							<Select value={selectedOrgId} onChange={handleOrgChange}>
								{remoteOrgs.map((o) => (
									<option value={o.Id} key={o.Id}>
										{o.Name}
									</option>
								))}
							</Select>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							onClick={handleConfirmClicked}
							disabled={isLoading}
							title="Confirm the remote organization/region change"
						>
							Confirm
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<StatusModal
				title="Error"
				message={`${error} Please try again later or contact your Epic representative if this continues to happen.`}
				isOpen={!!error}
				onClose={() => setError("")}
				status="error"
			></StatusModal>
		</>
	);
});
