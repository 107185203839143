/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Add or update license level-data for a customer app
 * @module Epic.AppOrchard.Core.UpdateCustomerAppLicenseButton
 */

import { AddIcon, CheckIcon, CloseIcon, TriangleUpIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
	As,
	Box,
	Button,
	ButtonProps,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { ISuccessHandler, useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { ConsumerTypes, EnvironmentType, PublicKeysAllowed, RequestedState } from "ao/data";
import { useUserSecurityState } from "ao/state/userSecurity";
import { CustomerAppLicense, IUserSecurity } from "ao/types";
import { getBaseUrl, isNullOrEmpty, stripURIScheme, titleCase } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { AOLink as Link, ConfirmationModal, GenerateClientSecret, JwtPublicKeyUpload, StatusAlert } from "..";

interface IProps {
	/** Current license to create or update
	 * If the license doesn't exist in the DB yet, it's expected that the caller
	 * at least populates the app ID
	 */
	license: CustomerAppLicense;
	/** The customer's current Epic version */
	epicVersion: number;
	appUsesOAuth2: boolean;
	appConsumerType: ConsumerTypes;
	appIsConfidentialClient: boolean;
	hasApiContextWarning?: boolean;
	hasApiContextWarningOverride?: boolean;
	centerModal?: boolean;
	/** component behaves slightly differently in build apps page vs the dashboard */
	isBuildApps?: boolean;
	/** JKU for environment */
	appJsonWebKeySetUrl: string | null;
	/** environment type to create/update the license for */
	envType: EnvironmentType;
	/** button-like component to render the button as */
	buttonAs?: As;
	/** called when changes successfully saved to server  */
	successHandler: (...args: any[]) => void;
	/** API call to the server to update the license */
	updateLicenseApi: (...args: any[]) => Promise<any>;
	publicKeysAllowed: PublicKeysAllowed;
	/** if populated, this org gets app licenses from another customer org so activating for their own org will be blocked */
	getsAppLicensesFromOrganization_Name: string;
}

type Props = IProps & ButtonProps;

/** Add or update license level-data for a customer app */
export const UpdateCustomerAppLicenseButton: FC<Props> = memo((props: Props) => {
	const {
		license,
		envType,
		buttonAs,
		successHandler,
		epicVersion,
		appJsonWebKeySetUrl,
		hasApiContextWarning,
		hasApiContextWarningOverride,
		centerModal,
		isBuildApps,
		updateLicenseApi,
		getsAppLicensesFromOrganization_Name,
		publicKeysAllowed,
		...rest
	} = props;

	//#region  state and handlers
	const { userSecurity } = useUserSecurityState((selectors) => selectors.getState(), []);
	const state = useMemo(() => new WorkflowState(props, userSecurity), [props, userSecurity]);
	const [hasError, setHasError] = useState<boolean>(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalHeader, setModalHeader] = useState<string>(state.modalHeader);

	//whether to show Out-Of-Context APIs warning
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
	const handleToggleWarningModal = useCallback((show: boolean) => () => setShowWarningModal(show), []);

	// whether to show main confirmation + choose cred enable workflow if (applicable)
	const [showConfirmationWorkflow, setShowConfirmationWorkflow] = useState<boolean>(true);

	// whether to show workflow to add client secrets
	const [showClientSecretWorkflow, setShowClientSecretWorkflow] = useState<boolean>(false);

	// whether to show JWT workflow
	const [showJwtKeyWorkflow, setShowJwtKeyWorkflow] = useState<boolean>(false);

	const resetState = useCallback(
		(resetError: boolean = false, closeModal: boolean = false) => () => {
			setModalHeader(state.modalHeader);
			setShowConfirmationWorkflow(true);
			setShowJwtKeyWorkflow(false);
			setShowClientSecretWorkflow(false);
			if (resetError) setHasError(false);
			if (closeModal) onClose();
		},
		[onClose, state.modalHeader],
	);

	// success action
	const toast = useToast();
	const handleSaveSuccess = useCallback<ISuccessHandler<typeof updateLicenseApi>>(
		(response) => {
			resetState(true, true)();
			successHandler(response);
			if (!isBuildApps) {
				toast({
					title: "Success",
					description: "Changes successfully saved",
					status: "success",
					duration: config.ToastDuration,
					isClosable: true,
				});
			}
		},
		[isBuildApps, resetState, successHandler, toast],
	);

	// create save request handlers
	const [savingState, updateLicenseFn] = useAsync(updateLicenseApi, {
		executeImmediately: false,
		displayName: "updateCustomerAppLicenseState",
		onSuccess: handleSaveSuccess,
		onFailure: () => {
			resetState()(); // reset workflow so any errors can be seen
			setHasError(true);
		},
	});
	const isSaving = isInFlight(savingState);

	const handleSaveClicked = useCallback(
		(
			secretHash: string = "",
			secretHash256: string = "",
			publicKey: string = "",
			copyOldCreds: boolean = false,
		) => () => {
			updateLicenseFn({
				AppId: license.clientApplication_Id,
				EnvType: envType,
				RequestedState: !state.isActivated ? RequestedState.Active : RequestedState.Update,
				SecretHash: secretHash,
				SecretHash256: secretHash256,
				JwtPublicKeyBlob: publicKey,
				CopyOldCreds: copyOldCreds,
			});
		},
		[envType, license.clientApplication_Id, state.isActivated, updateLicenseFn],
	);

	const handlePublicKeySaved = useCallback(
		(publicKey: string) => {
			handleSaveClicked("", "", publicKey)();
		},
		[handleSaveClicked],
	);

	const handleClientSecretsSaved = useCallback(
		(clientSecret: string, clientSecret256: string) => {
			handleSaveClicked(clientSecret, clientSecret256)();
		},
		[handleSaveClicked],
	);

	const handleUseSecretClicked = useCallback(() => {
		setModalHeader(`Generate Client Secret for ${state.envText}`);
		setShowConfirmationWorkflow(false);
		setShowJwtKeyWorkflow(false);
		setShowClientSecretWorkflow(true);
	}, [state.envText]);

	const handleUseJwtClicked = useCallback(() => {
		setModalHeader(`Upload JWT Verification Public Key for ${state.envText}`);
		setShowConfirmationWorkflow(false);
		setShowJwtKeyWorkflow(true);
		setShowClientSecretWorkflow(false);
	}, [state.envText]);

	const handleAcceptWarningModal = useCallback(() => {
		onOpen();
		setShowWarningModal(false);
	}, [onOpen]);

	//#endregion

	const UpdateLicenseButton = buttonAs || Button;

	return (
		<>
			{state.showOverallButton && (
				<>
					<UpdateLicenseButton
						{...rest}
						title={
							(!!getsAppLicensesFromOrganization_Name &&
								`Your system automatically receives all apps activated by ${getsAppLicensesFromOrganization_Name}. Work with that organization to get this app activated, if it's not already.`) ||
							rest.title ||
							state.warningText ||
							state.overallButtonTooltip
						}
						disabled={!!getsAppLicensesFromOrganization_Name || rest.disabled}
						onClick={
							rest.onClick
								? rest.onClick
								: hasApiContextWarning
								? handleToggleWarningModal(true)
								: onOpen
						}
						colorScheme="green"
						leftIcon={
							<>
								{state.warningText && (
									<WarningTwoIcon
										h={4}
										w={4}
										mr="2px"
										color="yellow.500"
										flexShrink="unset"
									/>
								)}
								{state.icon}
							</>
						}
					>
						{state.overallButtonCaption}
					</UpdateLicenseButton>
					<Modal
						isOpen={isOpen}
						onClose={resetState(true, true)}
						size="3xl"
						closeOnOverlayClick={false}
						isCentered={centerModal ?? false}
					>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>{modalHeader}</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								{showConfirmationWorkflow ? (
									state.confirmPrompt
								) : showClientSecretWorkflow ? (
									<GenerateClientSecret
										onStoreHashClicked={handleClientSecretsSaved}
										storeButtonCaption={state.storeHashButtonCaption}
										onCancelClicked={resetState()}
										isSaving={isSaving}
									/>
								) : showJwtKeyWorkflow ? (
									<JwtPublicKeyUpload
										onContinueClicked={handlePublicKeySaved}
										continueButtonCaption={state.continueWithJwtButtonCaption}
										onCancelClicked={resetState()}
										isSaving={isSaving}
										customerEpicVersion={epicVersion}
										isOwnApp={true}
										appJsonWebKeySetUrl={appJsonWebKeySetUrl}
										envType={envType}
										showPublicKeyWarning={
											publicKeysAllowed === PublicKeysAllowed.YesWithWarning
										}
									/>
								) : null}
							</ModalBody>
							{showConfirmationWorkflow && (
								<ModalFooter>
									<VStack>
										<Box>
											{state.showCopyOldButton && (
												<Flex>
													<Spacer />
													<Button
														colorScheme="green"
														onClick={handleSaveClicked("", "", "", true)}
														loadingText="Saving"
														isLoading={isSaving}
														mb="2"
														title={`Activate this app by copying credentials from the previously activated app version for ${state.envText}`}
													>
														{state.copyOldButtonCaption}
													</Button>
												</Flex>
											)}
											{state.showNoCredSaveButton && (
												<Button
													colorScheme="blue"
													mr="2"
													onClick={handleSaveClicked()}
													loadingText="Saving"
													isLoading={isSaving}
												>
													{state.noCredSaveButtonCaption}
												</Button>
											)}
											{state.showSecretButton && (
												<Button
													onClick={handleUseSecretClicked}
													colorScheme="blue"
													disabled={isSaving}
													mr="2"
												>
													{state.credButtonPrefix + "Client Secret"}
												</Button>
											)}
											{state.showJwtButton && (
												<Button
													onClick={handleUseJwtClicked}
													colorScheme="blue"
													disabled={isSaving}
													mr="2"
												>
													{state.credButtonPrefix + "JWT Signing Public Key"}
												</Button>
											)}
											<Button onClick={resetState(true, true)} disabled={isSaving}>
												Cancel
											</Button>
										</Box>
										{hasError && (
											<Box>
												<StatusAlert
													message="Error saving to the server. Contact your Epic representative if this continues to happen."
													status="error"
												/>
											</Box>
										)}
									</VStack>
								</ModalFooter>
							)}
						</ModalContent>
					</Modal>
					<ConfirmationModal
						title={
							<>
								{hasApiContextWarningOverride ? (
									<WarningTwoIcon
										fontSize="20px"
										color="yellow.500"
										marginRight="5px"
										marginBottom="3px"
									/>
								) : (
									<WarningIcon
										fontSize="20px"
										color="red"
										marginRight="5px"
										marginBottom="3px"
									/>
								)}

								{(hasApiContextWarningOverride ? "Warning" : "Error") +
									": Unsupported APIs Used"}
							</>
						}
						message={
							<>
								You {hasApiContextWarningOverride ? "should " : "can"}not activate this app
								for your environments or mark the app internal use until this is corrected.
								&nbsp;
								<Link url={getBaseUrl() + "Article?docId=usercontext"} target="_blank">
									Click to see documentation on application user context and APIs
								</Link>
							</>
						}
						acceptCaption={hasApiContextWarningOverride ? "Continue Anyway" : "Ok"}
						cancelCaption="Cancel"
						showCancel={hasApiContextWarningOverride}
						isOpen={showWarningModal}
						onCancel={handleToggleWarningModal(false)}
						onAccept={
							hasApiContextWarningOverride
								? handleAcceptWarningModal
								: handleToggleWarningModal(false)
						}
						isCentered={centerModal ?? false}
					/>
				</>
			)}
		</>
	);
});

/** calculate what kind of workflow is supported for this customer license + captions and helptext to show based on workflow */
class WorkflowState {
	isActivated: boolean = false;
	showOverallButton: boolean = false;
	showNoCredSaveButton: boolean = false;
	noCredSaveButtonCaption: string = "";
	overallButtonCaption: string = "";
	overallButtonTooltip: string = "";
	saveButtonCaption: string = "";
	warningText: string = "";
	showSecretButton: boolean = false;
	showJwtButton: boolean = false;
	showJkuWarning: boolean = false;
	storeHashButtonCaption: string = "";
	continueWithJwtButtonCaption: string = "";
	credButtonPrefix: string = "";
	showCopyOldButton: boolean = false;
	copyOldButtonCaption: string = "";
	envText: string = "";
	confirmPrompt: string = "";
	modalHeader: string = "";
	icon: JSX.Element = (<CheckIcon h={5} w={5} />);

	constructor(props: IProps, userSecurity: IUserSecurity) {
		const {
			license,
			epicVersion,
			appUsesOAuth2,
			appConsumerType,
			appIsConfidentialClient,
			appJsonWebKeySetUrl,
			envType,
			isBuildApps,
			publicKeysAllowed,
		} = props;

		this.isActivated = license.IsActivated(envType);
		const hasUsableJku =
			!isNullOrEmpty(stripURIScheme(appJsonWebKeySetUrl ?? "")) &&
			epicVersion >= config.MinEpicJkuVersion;
		const hasSavedCreds = license.HasSavedCreds(envType);
		let useBackendOauth = false;
		let isConfidentialClient = false;
		if (appUsesOAuth2) {
			useBackendOauth = appConsumerType === ConsumerTypes.Backend;
			isConfidentialClient = appIsConfidentialClient;
		}
		const needsCreds = useBackendOauth || isConfidentialClient;
		const hasOldCreds = license.HasPreviousCreds(envType);
		this.envText = envType === EnvironmentType.NonProd ? "Non-Production" : "Production";
		const shortEnvText = envType === EnvironmentType.NonProd ? "Non-Prod" : "Prod";
		const credentialsText = isBuildApps ? "Credentials" : "Creds";
		this.storeHashButtonCaption = "Save Hash";
		this.continueWithJwtButtonCaption = "Save Public Key";
		this.showOverallButton = true;
		let actionName = "";
		let addFor = false;

		if (!this.isActivated) {
			this.overallButtonCaption = `Activate for ${isBuildApps ? this.envText : shortEnvText}`;
			actionName = "activate";
			this.noCredSaveButtonCaption = titleCase(actionName);
			this.credButtonPrefix = "Activate with ";
			this.storeHashButtonCaption += " and Activate";
			this.continueWithJwtButtonCaption = "Activate";

			if (needsCreds && hasOldCreds) {
				this.showCopyOldButton = true;
				this.copyOldButtonCaption =
					"Activate Using Previous Version's " + (useBackendOauth ? "Public Key" : "Credentials");
			}
		} else if (hasSavedCreds && !needsCreds) {
			this.overallButtonCaption = `Remove ${
				isBuildApps ? this.envText : shortEnvText
			} ${credentialsText}`;
			actionName = "remove credentials";
			addFor = true;
			this.noCredSaveButtonCaption = titleCase(actionName);
			this.warningText =
				"This application is not set up to be able to use credentials (JWTs, or client secrets) currently, but it has some saved credentials for this environment. This could prevent the app from functioning correctly. Consider removing the saved credentials.";
			this.icon = <CloseIcon h={4} w={4} flexShrink="unset" />;
		} else if (!hasSavedCreds && !hasUsableJku && needsCreds) {
			this.overallButtonCaption = `Add ${isBuildApps ? this.envText : shortEnvText} ${credentialsText}`;
			actionName = "add credentials";
			addFor = true;
			this.warningText =
				"This application is set up to require credentials (JWTs, or client secrets) currently, but it does not have any saved credentials for this environment. This will prevent the app from functioning correctly. Add credentials for the app.";
			this.icon = <AddIcon h={4} w={4} flexShrink="unset" />;
			this.credButtonPrefix = "Add ";
		} else if (
			(isConfidentialClient && (hasSavedCreds || hasUsableJku)) ||
			(useBackendOauth && (hasSavedCreds || publicKeysAllowed >= PublicKeysAllowed.Yes))
		) {
			this.overallButtonCaption = `Change ${
				isBuildApps ? this.envText : shortEnvText
			} ${credentialsText}`;
			actionName = "change credentials";
			addFor = true;
			this.icon = <TriangleUpIcon h={5} w={5} />;
			this.credButtonPrefix = "Add New ";
		} else {
			this.showOverallButton = false;
		}

		this.showSecretButton = isConfidentialClient;
		this.showJwtButton =
			isConfidentialClient || (useBackendOauth && publicKeysAllowed >= PublicKeysAllowed.Yes);
		this.showJkuWarning = useBackendOauth && publicKeysAllowed === PublicKeysAllowed.No && !hasUsableJku;
		this.showNoCredSaveButton = !needsCreds || (!this.isActivated && needsCreds && hasUsableJku);
		this.overallButtonTooltip = `${titleCase(actionName, false)} ${
			addFor ? "for" : ""
		} this client for your ${this.envText} environment${envType === EnvironmentType.NonProd ? "s" : ""}`;
		this.modalHeader = `${titleCase(actionName)} for ${userSecurity.organizationName} ${this.envText}`;
		this.confirmPrompt = this.showJkuWarning
			? "This application is set up to require credentials (JWTs) currently, but it does not have any JWK Set URL for this environment. This will prevent the app from functioning correctly. Save the JWK Set URL before proceeding."
			: `Are you sure you want to ${actionName} ${addFor ? "for" : ""} this client for ${
					this.envText
			  }?`;
	}
}
