/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management Tab Panel
 * @module Epic.AppOrchard.ConnectorManagement.TabPanel
 */

import { AddIcon, DownloadIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, GridItem, Spacer } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { AsyncPlaceholder, MainContentSpinner, StatusModal } from "ao/components/Core";
import { ColumnPicker, FilterPanel } from "ao/components/Core/Filter";
import useFilters from "ao/components/Core/Filter/hooks/useFilters";
import { useSiteInfoState } from "ao/state/siteInfo";
import { IColumnDefinition, IListParameters, IUserSecurity } from "ao/types";
import { IConnectorManagementLookups } from "ao/types/connectorManagement";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { AppListAdminColumnDefs, AppListColumnDefs } from "../data/columns";
import { AppListFilterColumnMapping } from "../data/constants";
import { getConnectorApps, getFilteredConnectorAppExport, IRecordList } from "../data/data";
import {
	AppListFilterDefinitions,
	DefaultAppListAdminParameters,
	DefaultAppListParameters,
	IConnectorManagementFilterDefinition,
} from "../data/filters";
import { getTabSpecificText } from "../helpers";
import { ConnectorManagementList } from "./ConnectorManagementList";

export interface IProps {
	userSecurity: IUserSecurity;
	lookups: IConnectorManagementLookups;
	currentTab: number;
	filters: string;
}

const ConnectorManagementTabPanel: React.FC = () => {
	const { userSecurity, lookups, currentTab, filters } = useOutletContext<IProps>();

	//#region state and handlers
	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);
	const [recordList, setRecordList] = useState<IRecordList["recordList"]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const tabSpecificText = getTabSpecificText(currentTab);
	const tableRef = useRef<HTMLDivElement>(null);
	const [scrollPos, setScrollPos] = useState<number>(0);

	// Check if there are previous filters or search parameters in the URL
	const [hasLoadedFilters, setHasLoadedFilters] = useState<boolean>(
		useLocation().search === "" && filters === undefined ? false : true,
	);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const handleCloseErrorModal = useCallback(() => setShowErrorModal(false), []);

	//#endregion

	//#region load data
	const [DefaultListParameters, FilterDefinitions, ColumnDefinitions] = getListDefaults(
		currentTab,
		lookups,
		userSecurity.canManageConnectorsAppsOrgs && siteInfo.isAdminSite,
	);
	const [defaultListParameters] = useState(DefaultListParameters);
	const [filterDefinitions] = useState(FilterDefinitions);
	const [columnDefinitions] = useState(ColumnDefinitions);

	const {
		listParameters,
		listParameterRecords,
		handleClearAllListParameters,
		handleUpdateFilter,
		handleClearFilter,
		handleUpdatePagination,
		handleUpdateSortColumn,
		handleUpdateColumns,
	} = useFilters(defaultListParameters, filterDefinitions, columnDefinitions);

	const activeColumns = listParameters.columns.map(
		(columnKey) => columnDefinitions.filter((def) => columnKey === def.key)[0],
	);

	const handleUpdateScrollPosition = (event: React.UIEvent<HTMLElement, UIEvent>) => {
		if (tableRef.current) {
			setScrollPos(event.currentTarget.scrollLeft);
		}
	};

	const [loadingState, getAllRecordData] = useAsync(getRecordsAPI(currentTab), {
		executeImmediately: false,
		displayName: "getAllRecordData",
		onSuccess: (data: IRecordList) => {
			if (data) {
				setRecordList(data.recordList);
				setTotalCount(data.recordTotal);
			}
		},
		onFailure: () => setShowErrorModal(true),
	});

	//#endregion

	//#region data processing
	useEffect(() => {
		if (!hasLoadedFilters) {
			handleClearAllListParameters(defaultListParameters.filters);
		}
		setHasLoadedFilters(true);
	}, [defaultListParameters.filters, hasLoadedFilters, handleClearAllListParameters]);

	useEffect(() => {
		if (hasLoadedFilters) {
			getAllRecordData(listParameterRecords);
		}
	}, [listParameterRecords, defaultListParameters, getAllRecordData, hasLoadedFilters]);

	//#endregion

	return (
		<Grid
			gridTemplateColumns={{
				base: "minmax(0, 1fr)",
				md: "250px minmax(0, 1fr)",
			}}
			gridTemplateRows={{
				base: "auto auto",
				md: "auto 1fr",
			}}
			templateAreas={{
				base: `"filters" "listHeader" "main"`,
				md: `"filters listHeader" "filters main"`,
			}}
			gap="2"
		>
			<GridItem gridArea="filters">
				<FilterPanel
					searchText={`Search various text elements such as name, aliases, or Connector ${tabSpecificText} ID`}
					filterDefinitions={FilterDefinitions}
					filters={listParameters.filters}
					handleClearAllListParameters={handleClearAllListParameters}
					handleClearFilter={handleClearFilter}
					handleUpdateFilter={handleUpdateFilter}
					setColsWithFilter={true}
					filterToColumnMapping={getFilterColumnMap(currentTab)}
				/>
			</GridItem>
			<GridItem gridArea="listHeader">
				<Flex wrap={"wrap"}>
					{siteInfo.isAdminSite &&
						(userSecurity.isSuperAdministrator ||
							userSecurity.isEpicAdministrator ||
							userSecurity.canManageConnectorsAppsOrgs) && (
							<Button
								leftIcon={<AddIcon />}
								title={`Create ${tabSpecificText}`}
								colorScheme="blue"
								disabled={true}
							>
								{`Create ${tabSpecificText}`}
							</Button>
						)}
					<Button
						ml={2}
						leftIcon={<DownloadIcon />}
						onClick={() => getExportAPI(currentTab, listParameterRecords)}
						title={`Export the filtered list of ${tabSpecificText}s to a .CSV file`}
						disabled={totalCount === 0 || isInFlight(loadingState)}
					>
						{`Export Filtered ${tabSpecificText}s`}
					</Button>
					<Spacer />
					<ColumnPicker
						columnDefinitions={ColumnDefinitions}
						handleUpdateColumns={handleUpdateColumns}
						selectedColumns={listParameters.columns}
					/>
				</Flex>
			</GridItem>
			<GridItem gridArea="main">
				<AsyncPlaceholder inProgressIndicator={<MainContentSpinner />} asyncState={loadingState}>
					<ConnectorManagementList
						userSecurity={userSecurity}
						siteInfo={siteInfo}
						lookups={lookups}
						records={recordList}
						recordTotal={totalCount}
						columns={activeColumns}
						sorting={listParameters.sorting}
						pagination={listParameters.pagination}
						handleUpdatePagination={handleUpdatePagination}
						handleUpdateSortColumn={handleUpdateSortColumn}
						tabSpecificText={tabSpecificText}
						handleUpdateScrollPosition={handleUpdateScrollPosition}
						tableRef={tableRef}
						scrollPos={scrollPos}
					/>
					<StatusModal
						title="Error loading filter lookups"
						message="Error loading the filter lookups. Please try again later or contact the Connectors development team if this continues to happen."
						isOpen={showErrorModal}
						onClose={handleCloseErrorModal}
						status="error"
					/>
				</AsyncPlaceholder>
			</GridItem>
		</Grid>
	);
};

//#region functions
function getRecordsAPI(tab: number): (listParameterRecords: Record<string, string>) => Promise<IRecordList> {
	return getConnectorApps;
}

function getExportAPI(tab: number, listParameterRecords: Record<string, string>): Promise<void> {
	return getFilteredConnectorAppExport(listParameterRecords);
}

function getFilterColumnMap(tab: number): Record<string, string> {
	return AppListFilterColumnMapping;
}

function getListDefaults(
	tab: number,
	lookups: IConnectorManagementLookups,
	userHasAdminAccess?: boolean,
): [IListParameters, IConnectorManagementFilterDefinition[], IColumnDefinition[]] {
	const appListFilterDefinitions = AppListFilterDefinitions.map((filterDef) => {
		if (filterDef.lookupKey) {
			filterDef.options = lookups[filterDef.lookupKey as keyof typeof lookups];
		} else if (!filterDef.options) {
			filterDef.options = [];
		}
		return filterDef;
	});
	if (userHasAdminAccess) {
		return [DefaultAppListAdminParameters, appListFilterDefinitions, AppListAdminColumnDefs];
	} else {
		return [DefaultAppListParameters, appListFilterDefinitions, AppListColumnDefs];
	}
}

//#endregion

export default ConnectorManagementTabPanel;
