/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Spinner to use for when the main content is loading
 * @module Epic.AppOrchard.Core.MainContentSpinner
 */

import { Spinner, SpinnerProps, VisuallyHidden } from "@chakra-ui/react";
import React, { FC, memo } from "react";
import { BodyContent } from "../Frame";

/** Spinner to use for when the main content is loading */
interface ISpinnerProps {
	/** whether the spinner should take up whole page (true) or if there's going to be
	 * 	header shown during load (false)
	 */
	fullPage?: boolean;
}
type Props = ISpinnerProps & SpinnerProps;
export const MainContentSpinner: FC<Props> = memo((props: Props) => {
	const { fullPage, ...rest } = props;
	return (
		<BodyContent subtractHeight={fullPage ? 0 : 200}>
			<Spinner role="status" ml="45%" mt="5%" w="5rem" h="5rem" borderWidth={"5px"} {...rest}>
				<VisuallyHidden>Loading...</VisuallyHidden>
			</Spinner>
		</BodyContent>
	);
});
