/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Generic helper functions for the Connectors Management UI
 * @module Epic.AppOrchard.ConnectorManagement.Helpers
 */

import { ManagementTabs } from "ao/types/connectorManagement";

export function getTabSpecificText(tab: number): string {
	if (tab === ManagementTabs.Apps) {
		return "App";
	} else {
		return "";
	}
}
