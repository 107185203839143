/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Select option for multi select with checkbox
 * @module Epic.AppOrchard.Core.Filter.SelectOptionCheckbox
 */

import { Box, Button, Checkbox } from "@chakra-ui/react";
import { SelectOptionType, SelectOptionTypeType } from "ao/types";
import React, { FC } from "react";
import { components, GroupBase, OptionProps } from "react-select";

export const SelectOptionCheckbox: FC<OptionProps<SelectOptionType, true, GroupBase<SelectOptionType>>> = (
	props: OptionProps<SelectOptionType, true, GroupBase<SelectOptionType>>,
) => {
	const { isSelected, label, data } = props;

	return (
		<div>
			<components.Option {...props}>
				{data.type === SelectOptionTypeType.DefaultOptions ? (
					<Button title="Reset to the default" variant="solid" colorScheme="white">
						{label}
					</Button>
				) : (
					<Box
						onClick={(e) => {
							e.preventDefault();
						}} // prevent focus https://github.com/JedWatson/react-select/issues/4666
					>
						<Checkbox isChecked={isSelected}>{label}</Checkbox>
					</Box>
				)}
			</components.Option>
		</div>
	);
};
