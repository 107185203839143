/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file hook to use filters
 * @module Epic.AppOrchard.Core.Filter.Hooks
 */

import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
	IColumnDefinition,
	IFilterDefinition,
	IFilterListItem,
	IListParameters,
	IPagination,
	ISorting,
} from "ao/types";

// Convert URLSearchParams to IListParameters object
const parseToFilterObject = (
	params: URLSearchParams,
	defaultListParameters: IListParameters,
	filterDefs: IFilterDefinition[],
	columnDefs: IColumnDefinition[],
) => {
	const pagination: IPagination = {
		page: defaultListParameters.pagination.page,
		pageSize: defaultListParameters.pagination.pageSize,
	};
	const sorting: ISorting = {
		sortColumn: defaultListParameters.sorting.sortColumn,
		sortDirection: defaultListParameters.sorting.sortDirection,
	};
	let listParameters: IListParameters = {
		filters: [],
		pagination: pagination,
		sorting: sorting,
		columns: defaultListParameters.columns.filter((column) =>
			columnDefs.map((c) => c.key).includes(column),
		),
	};
	let filterList: IFilterListItem[] = [];

	const lowercaseParams = new URLSearchParams();
	params.forEach((value, key) => lowercaseParams.append(key.toLowerCase(), value));

	lowercaseParams.forEach((value, key) => {
		const filterDef = filterDefs.find((filterDef) => filterDef.key.toLowerCase() === key);
		if (filterDef) {
			if (filterDef.isMultiResponse) {
				filterList.push({
					key: filterDef.key,
					values: value.split(",").map((v) => parseInt(v)),
					isExcluded: !!lowercaseParams.get(`${key}x`),
				});
			} else {
				filterList.push({
					key: filterDef.key,
					value: value,
					isExcluded: !!lowercaseParams.get(`${key}x`),
				});
			}
		} else {
			if (key === "page") {
				listParameters.pagination.page = parseInt(value) || defaultListParameters.pagination.page;
			} else if (key === "pagesize") {
				const parsedPageSize = parseInt(value);
				listParameters.pagination.pageSize = parsedPageSize
					? Math.max(10, Math.min(parsedPageSize, 100))
					: defaultListParameters.pagination.pageSize;
			} else if (key === "sortcolumn") {
				listParameters.sorting.sortColumn = value;
			} else if (key === "sortdirection") {
				const parsedSortDirection = parseInt(value);
				listParameters.sorting.sortDirection = !isNaN(parsedSortDirection)
					? parsedSortDirection
					: defaultListParameters.sorting.sortDirection;
			} else if (key === "columns") {
				const columns = Array.from(new Set(value.split(",")));
				listParameters.columns =
					columns.filter((column) => columnDefs.map((c) => c.key).includes(column)) ||
					defaultListParameters.columns.filter((column) =>
						columnDefs.map((c) => c.key).includes(column),
					);
			}
		}
	});
	listParameters.filters = filterList;
	return listParameters;
};

// Convert IListParameters object to records
const parseParamObjectToListParameterRecords = (
	listParameters: IListParameters,
	defaultListParameters: IListParameters,
	columnDefs: IColumnDefinition[],
) => {
	const listParameterRecords: Record<string, string> = {};

	if (listParameters.pagination.page !== defaultListParameters.pagination.page)
		listParameterRecords["page"] = listParameters.pagination.page.toString();
	if (listParameters.pagination.pageSize !== defaultListParameters.pagination.pageSize)
		listParameterRecords["pageSize"] = listParameters.pagination.pageSize.toString();

	if (listParameters.sorting.sortColumn !== defaultListParameters.sorting.sortColumn)
		listParameterRecords["sortColumn"] = listParameters.sorting.sortColumn;
	if (listParameters.sorting.sortDirection !== defaultListParameters.sorting.sortDirection)
		listParameterRecords["sortDirection"] = listParameters.sorting.sortDirection.toString();

	if (
		listParameters.columns?.length &&
		listParameters.columns?.toString() !==
			defaultListParameters.columns
				?.filter((column) => columnDefs.map((c) => c.key).includes(column))
				.toString()
	)
		listParameterRecords["columns"] = listParameters.columns.join(",");

	listParameters.filters.forEach((filter) => {
		if (filter.value) {
			listParameterRecords[filter.key] = filter.value;
		}
		if (filter.values?.length) {
			listParameterRecords[filter.key] = filter.values.join(",");
		}
		if (filter.isExcluded) {
			const key = `${filter.key}X`;
			listParameterRecords[key] = "true";
		}
	});
	return listParameterRecords;
};

const useFilters = (
	defaultListParameters: IListParameters,
	filterDefs: IFilterDefinition[],
	columnDefs: IColumnDefinition[],
) => {
	const [searchParams, setSearchParams] = useSearchParams();
	// IListParameters object containing filters, pagination, sorting, and columns
	const listParameters: IListParameters = useMemo(() => {
		return searchParams
			? parseToFilterObject(searchParams, defaultListParameters, filterDefs, columnDefs)
			: defaultListParameters;
	}, [columnDefs, defaultListParameters, filterDefs, searchParams]);

	// Expose records sourced from listParameters
	const listParameterRecords: Record<string, string> = useMemo(() => {
		return parseParamObjectToListParameterRecords(listParameters, defaultListParameters, columnDefs);
	}, [columnDefs, defaultListParameters, listParameters]);

	const setFilter = useCallback(
		(newListParameterData: IListParameters) => {
			const stringifiedFilter = parseParamObjectToListParameterRecords(
				{
					...listParameters,
					...newListParameterData,
				},
				defaultListParameters,
				columnDefs,
			);
			setSearchParams(stringifiedFilter);
		},
		[columnDefs, defaultListParameters, listParameters, setSearchParams],
	);

	// Update a filter given the filter item
	const handleUpdateFilter = useCallback(
		(
			filterListItem: IFilterListItem,
			setColsWithFilter: boolean = false,
			filterColumnMappings: Record<string, string> = {},
		) => {
			const filterList: IFilterListItem[] = listParameters.filters.map((filterItem) => {
				if (filterItem.key === filterListItem.key) {
					return filterListItem;
				}
				return filterItem;
			});
			if (!filterList.map((f) => f.key).includes(filterListItem.key)) {
				filterList.push(filterListItem);
			}
			if (
				setColsWithFilter &&
				filterColumnMappings[filterListItem.key] &&
				!listParameters.columns.includes(filterColumnMappings[filterListItem.key])
			) {
				listParameters.columns.push(filterColumnMappings[filterListItem.key]);
			}
			setFilter({
				...listParameters,
				pagination: {
					page: defaultListParameters.pagination.page,
					pageSize: listParameters.pagination.pageSize,
				},
				filters: filterList,
				columns: listParameters.columns,
			});
		},
		[defaultListParameters.pagination.page, listParameters, setFilter],
	);

	// Clear a filter given a filter key
	const handleClearFilter = (filterKey: string) => {
		const filterList: IFilterListItem[] = listParameters.filters.map((filterItem) => {
			if (filterItem.key === filterKey) {
				return { ...filterItem, value: "", values: [], isExcluded: false };
			}
			return filterItem;
		});
		setFilter({
			...listParameters,
			pagination: {
				page: defaultListParameters.pagination.page,
				pageSize: listParameters.pagination.pageSize,
			},
			filters: filterList,
		});
	};

	// Reset the list parameters to defaults and provided organizations
	// Keep the current column settings
	const handleClearAllListParameters = (filtersToUse?: IFilterListItem[]) => {
		const resetListParameters = {
			...defaultListParameters,
			filters: filtersToUse ? [...filtersToUse] : [...defaultListParameters.filters],
			columns: [...listParameters.columns],
			sorting: defaultListParameters.sorting,
			pagination: {
				page: defaultListParameters.pagination.page,
				pageSize: listParameters.pagination.pageSize,
			},
		};
		setFilter(resetListParameters);
	};

	// Update the pagination
	const handleUpdatePagination = (pagination: IPagination) => {
		if (pagination.pageSize !== listParameters.pagination.pageSize) {
			setFilter({
				...listParameters,
				pagination: {
					page: defaultListParameters.pagination.page,
					pageSize: pagination.pageSize,
				},
			});
		} else {
			setFilter({ ...listParameters, pagination: pagination });
		}
	};

	// Update the sorting
	const handleUpdateSortColumn = useCallback(
		(columnKey: string) => {
			const sorting: ISorting = {
				sortColumn: listParameters.sorting.sortColumn,
				sortDirection: listParameters.sorting.sortDirection,
			};
			if (sorting.sortColumn === columnKey) {
				if (sorting.sortDirection === 0) sorting.sortDirection = 1;
				else sorting.sortDirection = 0;
			} else {
				sorting.sortColumn = columnKey;
				sorting.sortDirection = 1;
			}
			setFilter({
				...listParameters,
				pagination: {
					page: defaultListParameters.pagination.page,
					pageSize: listParameters.pagination.pageSize,
				},
				sorting: sorting,
			});
		},
		[defaultListParameters.pagination.page, listParameters, setFilter],
	);

	// Update the columns
	const handleUpdateColumns = useCallback(
		(columns: string[]) => {
			if (columns.length > 0) {
				setFilter({ ...listParameters, columns: columns });
			}
		},
		[listParameters, setFilter],
	);

	return {
		listParameters,
		listParameterRecords,
		handleClearAllListParameters,
		handleUpdateFilter,
		handleClearFilter,
		handleUpdatePagination,
		handleUpdateSortColumn,
		handleUpdateColumns,
	};
};

export default useFilters;
