/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file data types
 * @module Epic.AppOrchard.Data.Types
 */

import { IShowroomHomepage } from "ao/components/Showroom/data/data";
import { IEpicVersion, ISiteInfo, IUserSecurity, ProgramLevel } from "../types";

export enum ConsumerTypes {
	Undefined = 0,
	Employees = 100,
	Patient = 200,
	Backend = 300,
	PatientContact = 400,
}

export enum API_UsageScopes {
	Undefined = 0,
	Patient = 1,
	NonPatient = 2,
	Utility = 3,
	PatientContact = 4,
}

export enum API_Classifications {
	Undefined = 0,
	USCDI = 1,
	//the following two values were determined to not be needed
	//CCDS = 2,
	//CCDS_USCDI = 3,
	IndustryStandard = 4,
	EpicPublic = 5,
	EpicPrivate = 6,
	EpicInternal = 7,
	Kit = 8,
	Content = 9,
	ExternalPaymentPage = 10,
	VendorSpecific = 11,
}

export enum API_Warning_Types {
	Undefined = 0,
	Generic = 1,
	BetterExists = 2,
	Remove = 3,
	DevCommitted = 4,
}

export enum PriceModes {
	Undefined = 0,
	Free = 1,
	Paid = 2,
}

export enum EnvironmentType {
	Sandbox = 0,
	NonProd = 1,
	Prod = 2,
	Both = 3, //non-prod and prod
}

export enum ClientApplicationStatus {
	Draft = 0, // Saved, testing
	Internal = 50, // For Internal use apps
	Submitted = 100, // Submitted for publishing
	InReview = 200, // In review by Epic Admin
	ChangeRequested = 300, // Epic Admin requested changes
	ReadyToPublish = 400, // Approved and ready to publish
	USCDIRelease = 475, // Ready to be downloaded by customers in the USCDI site
	Active = 500, // Published, available for users to view & download
	Inactive = 900, // UnPublished, may be because new version is available
}

export enum DynamicClientGrantType {
	Jwt_Bearer = 0,
	Refresh_Tokens = 1,
	Client_Credentials = 2,
}

export const CDSHooksId = 1002;

export interface IAppStatusInfo {
	name: string;
	helptext: string;
	color: string;
	backgroundColor: string;
}

export function clientAppStatusInfo(status: ClientApplicationStatus): IAppStatusInfo {
	switch (status) {
		case ClientApplicationStatus.Draft:
			return {
				name: "Draft",
				helptext: "This app is still under development or initial testing",
				color: "white",
				backgroundColor: "#2b7ca7",
			};
		case ClientApplicationStatus.Internal:
			return {
				name: "Internal",
				helptext: "This app is ready for use and locked down from editing by most users",
				color: "white",
				backgroundColor: "#37835b",
			};
		case ClientApplicationStatus.Submitted:
			return {
				name: "Submitted",
				helptext: "This app has been submitted to the App Readiness Process",
				color: "white",
				backgroundColor: "#337ab7",
			};
		case ClientApplicationStatus.InReview:
			return {
				name: "In Review",
				helptext: "This app is being reviewed in the App Readiness Process",
				color: "white",
				backgroundColor: "#337ab7",
			};
		case ClientApplicationStatus.ChangeRequested:
			return {
				name: "Change Requested",
				helptext: "This app has changes requested by a reviewer as part of the App Readiness Process",
				color: "black",
				backgroundColor: "#f0ad4e",
			};
		case ClientApplicationStatus.ReadyToPublish:
			return {
				name: "Ready To Publish",
				helptext: "This app is ready to be published",
				color: "white",
				backgroundColor: "#37835b",
			};
		case ClientApplicationStatus.USCDIRelease:
			return {
				name: "Released",
				helptext: "This app is released for use with customer systems",
				color: "white",
				backgroundColor: "#37835b",
			};
		case ClientApplicationStatus.Active:
			return {
				name: "Active",
				helptext: "This app is released for use",
				color: "white",
				backgroundColor: "#37835b",
			};
		case ClientApplicationStatus.Inactive:
			return {
				name: "Inactive",
				helptext: "This app is inactive",
				color: "white",
				backgroundColor: "#4d5b69",
			};
	}
}

/** type of update being requested (currently only for customer license update)  */
export enum RequestedState {
	Active = 1,
	//Inactive = 2, not currently supported
	Update = 3,
}

export enum DownloadApprovedStatus {
	Unapproved = 0,
	Approved = 1,
	Declined = 2,
	NonProdOnly = 3,
	Overwritten = 4,
}

export enum InstallStatus {
	NotStarted = 0,
	InProgress = 10,
	Live = 20,
	Deactivated = 30,
}

export enum Feature {
	Incoming = 1,
	Outgoing = 2,
	Content = 3,
	Kit = 5,
	Subspace = 7,
}

export interface ISelectionType {
	Id: number;
	Name: string;
	Selected: boolean;
}

export interface ICheckboxOption {
	Id: number;
	Name: string;
	Selected?: boolean;
	Description?: string;
	Disable?: boolean;
}

export interface ICheckboxCascadeNode extends ISelectionType {
	//Children?: ICheckboxCascadeNode[] | null;
	Children?: any;
	ParentId?: number;
}

export interface IAllowedOriginRow {
	Url: string;
	EnvType: EnvironmentType;
}

export interface IAppResourceLink {
	Type: number;
	Link: string;
}

export interface IJwtSigningPublicKeyModel {
	X509CertBlob: string;
	RsaCspBlob: string;
	Thumbprint: string;
}

export interface IOutgoingFunctionalityModel extends ISelectionType {
	Description: string;
}

export interface ISingleKeyedDbResource {
	Id: number;
}
export interface IAPI extends ISingleKeyedDbResource {
	DocumentId: number;
}

export interface IAPIForOrgModel extends IAPI {
	Name: string;
	Keywords: string;
	IsFhir: boolean;
	FhirVersion?: string;
	IsKit: boolean;
	IsSubspace: boolean;
	IsStargate: boolean;
	IsCDSHooks: boolean;
	HasOrgAccess: boolean;
	HasPermissionAccess: boolean;
	Groups: string;
	IsDeprecated?: boolean;
	MayBeDeprecated?: boolean;
	/** Whether the API is in a published grouper */
	HasPublishedGrouper?: boolean;
	UsageScopes: string;
	MinEpicVersion?: IEpicVersion;
	IsVisibleSwimLanes?: boolean;
	Style?: object;
	Tooltip?: string;
}

export interface API_Warning {
	API_Id: number;
	BetterApi: number;
	BetterApiName: string;
	BetterApiPublished: boolean;
	Classification: API_Classifications;
	ConsumerType: ConsumerTypes;
	Id: number;
	Suppress: boolean;
	Type: API_Warning_Types;
}

export interface ISwimLanesAPI extends IAPIForOrgModel {
	isVisible: boolean;
}

export interface IContentSchemaViewModel {
	Id: number;
	Name: string;
	Documents: IContentSchemaDocumentViewModel[];
}

export interface IContentSchemaDocumentViewModel {
	Id: number;
	Name: string;
	HasOrgAccess: boolean;
	StartVersionId: number;
	EndVersionId: number;
}

export interface IAppImages {
	screenshots: IAppImage[];
	thumbnail: IAppImage | undefined;
	oldImageIDs: number[];
}

export interface IAppImage {
	Id: number;
	ContentType?: string;
	ImageData: string;
	IsThumbnail?: boolean;
}

//format for POST
export interface IScreenshotSaveModel {
	appId: number;
	deletedEntries: number[];
	images: string[];
	thumbnail: string;
}

export interface IUploadAppAgreementModel {
	appId: number;
	appAgreement: FormData;
}

export interface IJwtSigningKeyValidationResponse {
	jwtSigningKeyBlob: string;
	jwtSigningKeyThumbprint: string;
}

export interface IGenericAPIResponse {
	success: boolean;
	message: string;
}

export interface IGenericAPIResponseWrongCasing {
	Success: boolean;
	Message: string;
}

export interface IGenericAPIDataResponse {
	Data: IGenericAPIResponseWrongCasing;
}

export interface ISaveConnectorAPIResponse extends IGenericAPIResponseWrongCasing {
	Id: string;
}

export interface IUpdateCustomerAppLicenseRequest {
	AppId: number;
	RequestedState: RequestedState;
	EnvType: EnvironmentType;
	SecretHash: string;
	SecretHash256: string;
	JwtPublicKeyBlob: string;
	PublicKeyBlobIsCSP: boolean;
	CopyOldCreds: boolean | null;
}

export interface IUpdateSubmittedRequest {
	appId: number;
	nonProdJku: string;
	prodJku: string;
	redirectUris: string[];
}

export interface IConnectionHubRequest {
	appId: number;
	openEpicTermsId: number;
	connectionHubTermsId: number;
}

export interface IQuestionnaireForAppEdit {
	IsStarted: boolean;
	IsComplete: boolean;
	IsApproved: boolean;
	IsEditable: boolean;
	IsRequired: boolean;
	IsNewestVersion: boolean;
	ContainsApproved: boolean;
	ReviewStatus: QuestionnaireReviewStatus;
}

export interface IRequestQuestionnaireReview {
	appId: number;
	questionnaireType: QuestionnaireType;
}

export enum QuestionnaireReviewStatus {
	NotRequested = 0,
	Requested = 1,
	ChangesRecommended = 2,
	Completed = 3,
}

export interface ApiWarningDictionary {
	[index: number]: API_Warning;
}

export interface IAppVersion {
	Id: number;
	Organization_Id: number;
	Version: string;
	Status: ClientApplicationStatus;
	LiveOn: Date;
}
export interface IOrganization {
	id: number;
	name: string;
	/** SLG ID */
	epicCommunityMemberId: number;
	programLevel: ProgramLevel;
}

export interface IOrganizationWithAssignment extends IOrganization {
	/** Whether the current user is assigned to work with this org */
	isUserAssigned: boolean;
}

export interface IUserAvailableOrganizations {
	organizations: IOrganizationWithAssignment[];
	defaultOrganization: IOrganizationWithAssignment | null;
}

export interface IOnOrgChangeCallback {
	(selectedOrg: IOrganizationWithAssignment): void;
}

export interface IOnShowingPrefsDropdownCallback {
	(showingPrefsEditor: boolean): void;
}

export interface IOrgSelectionProps {
	selectedOrg: IOrganizationWithAssignment;
	/** Default org to show to use in org selection workflows */
	defaultOrg: IOrganizationWithAssignment;
	availableOrgs: IOrganizationWithAssignment[];
	buttonHelptext: string;
	/** allows  caller to control whether button that triggers dropdown is enabled  */
	toggleEnabled?: boolean;
	logoSize: LogoSize;
	/** Whether users can toggle the filter for only showing orgs assigned to the user */
	allowChangeFilterByAssigned?: boolean;
	/** size of the dropdown trigger */
	triggerButtonSize?: string;
	/** size of the set as default/favorite button */
	setAsDefaultButtonSize?: string;
	/** overrides default number of 6 orgs per page */
	orgsPerPageInDropdown?: number;
}

export type LogoSize = "tiny" | "small" | "medium" | "large";

export interface IOrgLogoProps {
	org: IOrganizationWithAssignment;
	logoSize: LogoSize;
}

export type RootDependentData = [
	IUserSecurity | undefined,
	IUserAvailableOrganizations | undefined,
	ISiteInfo | undefined,
	IShowroomHomepage | undefined,
];

export interface IUpdateAllowSyncOverrideOrgsRequestModel {
	AppId: number;
	AllowSyncOverrideOrgs: ISelectionType[];
}

/* Questionnaire Enums */
export enum QuestionnaireType {
	AppReadiness = 0,
	DataUse = 1,
	EnrollmentCriteria = 2,
	SelfAuditAttestation = 3,
	EnrollmentQuestionnaire = 4,
	Internal = 5,
	CustomerAppAttestation = 6,
}

export interface ISimpleError {
	VerboseMessage: string;
	ShortMessage: string;
}

export interface IClientError {
	Success: boolean;
	Status: string;
	ErrorMsg: string;
	ErrorSubMsg: string;
	AjaxUrl: string;
}

export interface IDocumentRevision {
	id: number;
	name: string;
	statusId: DocumentRevision_Status;
}

/* Document Enums */
export enum DocumentRevision_Status {
	Undefined = 0,
	Draft = 1,
	Published = 2,
	Old = 3,
	Deleted = 4,
}

export interface ILocationState {
	from: string;
}

export enum FhirIdVersionType {
	VersionA = 1, // Epic default
	VersionB = 2, // 64 char limited FHIR IDs
	Any = 999,
}

export enum SmartScope {
	V1 = 0,
	V2 = 1,
}

export enum ClientCredentialsRestriction {
	None = 0,
	HideKeysForSandbox = 1,
	HideKeysForNewApps = 2,
	HideKeysForOldApps = 3,
}

export enum PublicKeysAllowed {
	No = 0,
	Yes = 1,
	YesWithWarning = 2,
}

export interface INameIdPair {
	id: number;
	name: string;
}
