/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file File not found page component
 * @module Epic.AppOrchard.Modules.Notfound.NotFound
 */

import { Box, Heading, Text } from "@chakra-ui/layout";
import routes from "ao/routes";
import React, { FC } from "react";
import { Link as RouteLink, useLocation } from "react-router-dom";

/**
 * File Not Found (404) page component
 */
const NotFound: FC = () => {
	const location = useLocation();

	return (
		<>
			<Heading as="h1">
				<Text as="span">
					The requested{" "}
					<Text as="span" fontWeight="bold">
						{location.pathname}
					</Text>{" "}
					path does not exist. Try again later.
				</Text>
			</Heading>

			{process.env.NODE_ENV === "development" && (
				<div style={{ padding: "20px" }}>
					<Heading as="h2" mb="10px">
						Registered routes:
					</Heading>
					{routes.map(({ title, path }) => (
						<Box key={path} fontSize="1.3em" ml="10px">
							<RouteLink to={path}>{title}</RouteLink>
						</Box>
					))}
				</div>
			)}
		</>
	);
};

export default NotFound;
